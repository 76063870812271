import { ICategory } from '@server/data/models/category';
// import { useEffect } from 'react';
// import { useCategoryLazyQuery } from 'src/generated/graphql';

// const useCategoryList = () => {
//   const [loadCategory, { loading, error, data }] = useCategoryLazyQuery();

//   useEffect(() => {
//     loadCategory({
//       variables: {
//         page: 1,
//         limit: 1000,
//         orderBy: 'position_on_menu|asc',
//       },
//     });
//   }, []);

//   const categories = !!data
//     ? ((data?.categories as unknown) as ICategory[])
//     : [];
//   return { loading, error, categories };
// };

import cached from '@cache/category';
const useCategoryList = () => ({
  loading: false,
  error: null,
  categories: cached.data.categories,
});

export default useCategoryList;
