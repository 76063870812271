import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHoc from '@apollo/client/react/hoc';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export type AddCartMultipleItem = {
  id: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
};

export type Address = {
  __typename?: 'Address';
  id: Scalars['ID'];
  customerId?: Maybe<Scalars['Int']>;
  contactName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['String']>;
  ward?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
};

export type Attribute = {
  __typename?: 'Attribute';
  productCode?: Maybe<Scalars['String']>;
  productLength?: Maybe<Scalars['String']>;
  productWidth?: Maybe<Scalars['String']>;
  productHeight?: Maybe<Scalars['String']>;
  productWeight?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  listPrice?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  galleries?: Maybe<Array<Maybe<Scalars['String']>>>;
  metaTitle?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  productType?: Maybe<ProductAttributeValue>;
  brand?: Maybe<ProductAttributeValue>;
  origin?: Maybe<ProductAttributeValue>;
  unit?: Maybe<ProductAttributeValue>;
  googleIndex?: Maybe<Scalars['Boolean']>;
  canViewRating?: Maybe<Scalars['Boolean']>;
  sanphamcobaogia?: Maybe<Scalars['Boolean']>;
};

export type Brand = {
  __typename?: 'Brand';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type Cart = {
  __typename?: 'Cart';
  id: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
  actualQuantity?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  listPrice?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  disabled?: Maybe<Scalars['Boolean']>;
  isContactProduct?: Maybe<Scalars['Boolean']>;
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['Int']>;
  productsetId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  childrenCount?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  metaKeyword?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  imageIcon?: Maybe<Scalars['String']>;
  includeInMenu?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Int']>;
  urlKey?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  productCount?: Maybe<Scalars['Int']>;
  typicalProduct?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<Product>>>;
  flashSale?: Maybe<Scalars['Int']>;
  bannerUrl?: Maybe<Scalars['String']>;
  bannerThumbnailUrl?: Maybe<Scalars['String']>;
};

export type Checkout = {
  __typename?: 'Checkout';
  cart?: Maybe<Array<Maybe<Cart>>>;
  promotion?: Maybe<CheckoutSimulationPromotion>;
};

export type CheckoutInput = {
  source?: Maybe<Array<Maybe<CheckoutSourceInput>>>;
};

export type CheckoutSimulationDiscount = {
  __typename?: 'CheckoutSimulationDiscount';
  discountManagementId?: Maybe<Scalars['Int']>;
  minPurchase?: Maybe<Scalars['Int']>;
  maxPurchase?: Maybe<Scalars['Int']>;
  typeMinPurchase?: Maybe<Scalars['Int']>;
  typeMaxPurchase?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  discountPercent?: Maybe<Scalars['Int']>;
  canApplyDiscount?: Maybe<Scalars['Boolean']>;
};

export type CheckoutSimulationGift = {
  __typename?: 'CheckoutSimulationGift';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
};

export type CheckoutSimulationPromotion = {
  __typename?: 'CheckoutSimulationPromotion';
  discount?: Maybe<Array<Maybe<CheckoutSimulationDiscount>>>;
  gift?: Maybe<Array<Maybe<CheckoutSimulationGift>>>;
  taxPercent?: Maybe<Array<Maybe<CheckoutSimulationTax>>>;
  totalProductPrice?: Maybe<Scalars['Int']>;
  totalPrice?: Maybe<Scalars['Int']>;
  totalDiscount?: Maybe<Scalars['Int']>;
  totalTax?: Maybe<Scalars['Int']>;
  totalPayment?: Maybe<Scalars['Int']>;
  discountType?: Maybe<Scalars['String']>;
  discountValue?: Maybe<Scalars['Int']>;
};

export type CheckoutSimulationTax = {
  __typename?: 'CheckoutSimulationTax';
  productId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

export type CheckoutSourceInput = {
  id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type CreateGuestOrderInput = {
  contactName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  ward?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  exportVat?: Maybe<Scalars['Boolean']>;
  totalDiscount?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  billingCompanyName?: Maybe<Scalars['String']>;
  billingCompanyTaxNumber?: Maybe<Scalars['String']>;
  billingCompanyAddress?: Maybe<Scalars['String']>;
  billingCompanyNote?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<OrderGuestProduct>>>;
  discountManagementId?: Maybe<Scalars['Int']>;
  promotionGiftProductId?: Maybe<Scalars['Int']>;
};

export type CreateOrderInput = {
  shippingId: Scalars['String'];
  paymentMethod: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  exportVat?: Maybe<Scalars['Boolean']>;
  totalDiscount?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  billingCompanyName?: Maybe<Scalars['String']>;
  billingCompanyTaxNumber?: Maybe<Scalars['String']>;
  billingCompanyAddress?: Maybe<Scalars['String']>;
  billingCompanyNote?: Maybe<Scalars['String']>;
  discountManagementId?: Maybe<Scalars['Int']>;
  promotionGiftProductId?: Maybe<Scalars['Int']>;
};


export type District = {
  __typename?: 'District';
  id: Scalars['ID'];
  provinceId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Homepage = {
  __typename?: 'Homepage';
  id: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type Keyword = {
  __typename?: 'Keyword';
  title?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export type KeywordFuse = {
  __typename?: 'KeywordFuse';
  item?: Maybe<Keyword>;
  refIndex?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Float']>;
};

export type Manufacturer = {
  __typename?: 'Manufacturer';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  manufacturerId?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  linkImage?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  saveAddress?: Maybe<Scalars['Boolean']>;
  deleteAddress?: Maybe<Scalars['Boolean']>;
  addCartItem?: Maybe<Scalars['Boolean']>;
  updateCartItem?: Maybe<Scalars['Boolean']>;
  deleteCartItem?: Maybe<Scalars['Boolean']>;
  createOrder?: Maybe<OrderCreationResponse>;
  createGuestOrder?: Maybe<OrderResponse>;
  trackingRecentProduct?: Maybe<Scalars['Boolean']>;
  interestProduct?: Maybe<Scalars['Boolean']>;
  deleteInterestedProduct?: Maybe<Scalars['Boolean']>;
  register?: Maybe<User>;
  login?: Maybe<User>;
  logout: Scalars['Boolean'];
  updateUser?: Maybe<Scalars['Boolean']>;
  loginSocial?: Maybe<User>;
  resetPassword?: Maybe<Scalars['Boolean']>;
  applyToResetPassword?: Maybe<Scalars['Boolean']>;
};


export type MutationSaveAddressArgs = {
  id: Scalars['ID'];
  contactName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  default?: Maybe<Scalars['Boolean']>;
  address: Scalars['String'];
  ward: Scalars['String'];
  district: Scalars['String'];
  province: Scalars['String'];
};


export type MutationDeleteAddressArgs = {
  id: Scalars['ID'];
};


export type MutationAddCartItemArgs = {
  id: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
};


export type MutationUpdateCartItemArgs = {
  id: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
};


export type MutationDeleteCartItemArgs = {
  id: Scalars['ID'];
};


export type MutationCreateOrderArgs = {
  input?: Maybe<CreateOrderInput>;
};


export type MutationCreateGuestOrderArgs = {
  input?: Maybe<CreateGuestOrderInput>;
};


export type MutationTrackingRecentProductArgs = {
  id?: Maybe<Scalars['ID']>;
  customerId?: Maybe<Scalars['ID']>;
};


export type MutationInterestProductArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationDeleteInterestedProductArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationRegisterArgs = {
  name: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  recaptchaResponse?: Maybe<Scalars['String']>;
  recaptchaType?: Maybe<Scalars['String']>;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  cartItems?: Maybe<Array<Maybe<AddCartMultipleItem>>>;
};


export type MutationUpdateUserArgs = {
  customerId: Scalars['ID'];
  customerName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Int']>;
  taxCompanyName?: Maybe<Scalars['String']>;
  taxCompanyAddress?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  changePassword?: Maybe<Scalars['Boolean']>;
  oldPassword?: Maybe<Scalars['String']>;
  newPassword?: Maybe<Scalars['String']>;
};


export type MutationLoginSocialArgs = {
  accessToken: Scalars['String'];
  type: Scalars['String'];
  cartItems?: Maybe<Array<Maybe<AddCartMultipleItem>>>;
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationApplyToResetPasswordArgs = {
  token: Scalars['String'];
  password: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  orderCode?: Maybe<Scalars['Int']>;
  totalPayment?: Maybe<Scalars['Int']>;
  orderStatus?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderCreationErrorProduct = {
  __typename?: 'OrderCreationErrorProduct';
  productId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type OrderCreationResponse = {
  __typename?: 'OrderCreationResponse';
  success?: Maybe<Scalars['Boolean']>;
  response?: Maybe<OrderResponse>;
  errorProduct?: Maybe<Array<Maybe<OrderCreationErrorProduct>>>;
  message?: Maybe<Scalars['String']>;
};

export type OrderDetail = {
  __typename?: 'OrderDetail';
  id: Scalars['ID'];
  orderCode?: Maybe<Scalars['Int']>;
  paymentStatus?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<Scalars['String']>;
  orderStatus?: Maybe<Scalars['Int']>;
  totalProductPrice?: Maybe<Scalars['Int']>;
  totalDiscount?: Maybe<Scalars['Int']>;
  totalTax?: Maybe<Scalars['Int']>;
  shippingPrice?: Maybe<Scalars['Int']>;
  totalPayment?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  shippingInfo?: Maybe<OrderDetailShipping>;
  billingInfo?: Maybe<OrderDetailBilling>;
  products?: Maybe<Array<Maybe<OrderProduct>>>;
};

export type OrderDetailBilling = {
  __typename?: 'OrderDetailBilling';
  companyName?: Maybe<Scalars['String']>;
  companyTax?: Maybe<Scalars['String']>;
  companyAddress?: Maybe<Scalars['String']>;
};

export type OrderDetailShipping = {
  __typename?: 'OrderDetailShipping';
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type OrderGuestProduct = {
  id: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
};

export type OrderPage = {
  __typename?: 'OrderPage';
  total?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type OrderProduct = {
  __typename?: 'OrderProduct';
  id?: Maybe<Scalars['ID']>;
  productName?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  typePrice?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  totalPrice?: Maybe<Scalars['Int']>;
  isContactProduct?: Maybe<Scalars['Boolean']>;
  discountPrice?: Maybe<Scalars['Int']>;
  isGift?: Maybe<Scalars['Boolean']>;
};

export type OrderResponse = {
  __typename?: 'OrderResponse';
  id?: Maybe<Scalars['ID']>;
  orderCode?: Maybe<Scalars['Int']>;
};

export type OrderResult = {
  __typename?: 'OrderResult';
  data?: Maybe<Array<Maybe<Order>>>;
  page?: Maybe<OrderPage>;
};

export type OrderSearch = {
  __typename?: 'OrderSearch';
  id: Scalars['ID'];
  orderStatus?: Maybe<Scalars['Int']>;
  totalProductPrice?: Maybe<Scalars['Int']>;
  totalDiscount?: Maybe<Scalars['Int']>;
  totalTax?: Maybe<Scalars['Int']>;
  shippingPrice?: Maybe<Scalars['Int']>;
  totalPayment?: Maybe<Scalars['Int']>;
  exportVat?: Maybe<Scalars['Boolean']>;
  shippingInfo?: Maybe<OrderDetailShipping>;
  billingInfo?: Maybe<OrderDetailBilling>;
  products?: Maybe<Array<Maybe<OrderProduct>>>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type Page = {
  __typename?: 'Page';
  total?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  current?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  productId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  productsetId?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  inventoryType?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  quantityAvailable?: Maybe<Scalars['Int']>;
  minSaleQty?: Maybe<Scalars['Int']>;
  maxSaleQty?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  attributes?: Maybe<Attribute>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  shopeeId?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  hasVideo?: Maybe<Scalars['Boolean']>;
  isContactProduct?: Maybe<Scalars['Boolean']>;
  isInterested?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Array<Maybe<ProductCategory>>>;
};

export type ProductAttributeValue = {
  __typename?: 'ProductAttributeValue';
  id: Scalars['ID'];
  value?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  path?: Maybe<Scalars['String']>;
  urlKey?: Maybe<Scalars['String']>;
};

export type ProductDetailWithReview = {
  __typename?: 'ProductDetailWithReview';
  product?: Maybe<Product>;
  ratings?: Maybe<Array<Maybe<ShopeeProductRatingItem>>>;
  summary?: Maybe<ShopeeProductRatingSummary>;
};

export type ProductResult = {
  __typename?: 'ProductResult';
  products?: Maybe<Array<Maybe<Product>>>;
  page?: Maybe<Page>;
};

export type Province = {
  __typename?: 'Province';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  address?: Maybe<Array<Maybe<Address>>>;
  manufacturers?: Maybe<Array<Maybe<Manufacturer>>>;
  brand?: Maybe<Brand>;
  cart?: Maybe<Array<Maybe<Cart>>>;
  checkout?: Maybe<Checkout>;
  categories?: Maybe<Array<Maybe<Category>>>;
  category?: Maybe<Category>;
  categoryFilterQuery?: Maybe<Array<Maybe<Category>>>;
  categoriesQuery?: Maybe<Array<Maybe<Category>>>;
  categoryTree?: Maybe<Array<Maybe<Category>>>;
  homepage?: Maybe<Homepage>;
  keywords?: Maybe<Array<Maybe<KeywordFuse>>>;
  suggestKeywords?: Maybe<Array<Maybe<SuggestKeyword>>>;
  province?: Maybe<Array<Maybe<Province>>>;
  district?: Maybe<Array<Maybe<District>>>;
  ward?: Maybe<Array<Maybe<Ward>>>;
  orders?: Maybe<OrderResult>;
  order?: Maybe<OrderDetail>;
  orderSearch?: Maybe<Array<Maybe<OrderSearch>>>;
  simulateOrder?: Maybe<SimulateOrder>;
  simulatePromotion?: Maybe<SimulatePromotion>;
  products?: Maybe<Array<Maybe<Product>>>;
  product?: Maybe<Product>;
  searchProduct?: Maybe<ProductResult>;
  searchProductV2?: Maybe<ProductResult>;
  shopeeItem?: Maybe<ShopeeItem>;
  shopeeItemComment?: Maybe<ShopeeProductRating>;
  isInterestedProduct?: Maybe<Scalars['Boolean']>;
  productWithReview?: Maybe<ProductDetailWithReview>;
  me?: Maybe<User>;
};


export type QueryManufacturersArgs = {
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
};


export type QueryBrandArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryCheckoutArgs = {
  input?: Maybe<CheckoutInput>;
};


export type QueryCategoryArgs = {
  id?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
};


export type QueryCategoryFilterQueryArgs = {
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
};


export type QueryCategoriesQueryArgs = {
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
};


export type QueryCategoryTreeArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryKeywordsArgs = {
  pattern?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QuerySuggestKeywordsArgs = {
  filter?: Maybe<SuggestKeywordFilter>;
};


export type QueryDistrictArgs = {
  parentId: Scalars['String'];
};


export type QueryWardArgs = {
  parentId: Scalars['String'];
};


export type QueryOrdersArgs = {
  page: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryOrderSearchArgs = {
  type: Scalars['Int'];
  value: Scalars['String'];
  recaptchaResponse: Scalars['String'];
};


export type QuerySimulateOrderArgs = {
  input?: Maybe<SimulateOrderInput>;
};


export type QuerySimulatePromotionArgs = {
  input?: Maybe<SimulatePromotionInput>;
};


export type QueryProductArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QuerySearchProductArgs = {
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Int']>;
  keyword?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  status?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['ID']>;
  customerInterestedProductId?: Maybe<Scalars['ID']>;
  brandId?: Maybe<Scalars['Int']>;
};


export type QuerySearchProductV2Args = {
  filter?: Maybe<SearchProductFilter>;
};


export type QueryShopeeItemArgs = {
  itemid?: Maybe<Scalars['String']>;
};


export type QueryShopeeItemCommentArgs = {
  itemid?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryIsInterestedProductArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryProductWithReviewArgs = {
  id: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type SearchProductFilter = {
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Int']>;
  keyword?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  status?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['ID']>;
  customerInterestedProductId?: Maybe<Scalars['ID']>;
  brandId?: Maybe<Scalars['Int']>;
  isQuotation?: Maybe<Scalars['Boolean']>;
};

export type ShopeeItem = {
  __typename?: 'ShopeeItem';
  videoUrl?: Maybe<Scalars['String']>;
};

export type ShopeeProductRating = {
  __typename?: 'ShopeeProductRating';
  ratings?: Maybe<Array<Maybe<ShopeeProductRatingItem>>>;
  summary?: Maybe<ShopeeProductRatingSummary>;
};

export type ShopeeProductRatingItem = {
  __typename?: 'ShopeeProductRatingItem';
  itemid?: Maybe<Scalars['String']>;
  ratingStar?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  author?: Maybe<Scalars['String']>;
  authorLink?: Maybe<Scalars['String']>;
  authorPortrait?: Maybe<Scalars['String']>;
};

export type ShopeeProductRatingSummary = {
  __typename?: 'ShopeeProductRatingSummary';
  total?: Maybe<Scalars['Int']>;
  ratingCount?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type SimulateDiscount = {
  __typename?: 'SimulateDiscount';
  discountManagementId: Scalars['ID'];
  minPurchase?: Maybe<Scalars['Int']>;
  maxPurchase?: Maybe<Scalars['Int']>;
  typeMinPurchase?: Maybe<Scalars['Int']>;
  typeMaxPurchase?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  discountPercent?: Maybe<Scalars['Int']>;
  canApplyDiscount?: Maybe<Scalars['Boolean']>;
};

export type SimulateGift = {
  __typename?: 'SimulateGift';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
};

export type SimulateOrder = {
  __typename?: 'SimulateOrder';
  discountManagementId: Scalars['ID'];
  minPurchase?: Maybe<Scalars['Int']>;
  maxPurchase?: Maybe<Scalars['Int']>;
  typeMinPurchase?: Maybe<Scalars['Int']>;
  typeMaxPurchase?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  discountPercent?: Maybe<Scalars['Int']>;
  canApplyDiscount?: Maybe<Scalars['Boolean']>;
};

export type SimulateOrderInput = {
  products?: Maybe<Array<Maybe<SimulateProductInput>>>;
};

export type SimulateProductInput = {
  id: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type SimulatePromotion = {
  __typename?: 'SimulatePromotion';
  discount?: Maybe<Array<Maybe<SimulateDiscount>>>;
  gift?: Maybe<Array<Maybe<SimulateGift>>>;
};

export type SimulatePromotionInput = {
  products?: Maybe<Array<Maybe<SimulateProductInput>>>;
};

export type SuggestKeyword = {
  __typename?: 'SuggestKeyword';
  keyword?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

export type SuggestKeywordFilter = {
  keyword?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  categoryIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  storeId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  approved?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  taxCompanyName?: Maybe<Scalars['String']>;
  taxCompanyAddress?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phone2?: Maybe<Scalars['String']>;
  phone3?: Maybe<Scalars['String']>;
  cart?: Maybe<Scalars['String']>;
  newsletter?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Int']>;
  loginType?: Maybe<Scalars['Int']>;
};

export type Ward = {
  __typename?: 'Ward';
  id: Scalars['ID'];
  districtId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AddressItemFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'id' | 'customerId' | 'contactName' | 'email' | 'phone' | 'default' | 'address' | 'ward' | 'district' | 'province' | 'fullAddress'>
);

export type CartItemFragment = (
  { __typename?: 'Cart' }
  & Pick<Cart, 'id' | 'quantity' | 'actualQuantity' | 'name' | 'image' | 'price' | 'listPrice' | 'status' | 'disabled' | 'isContactProduct'>
);

export type CategoryItemFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'name' | 'parentId' | 'path' | 'urlKey' | 'flashSale' | 'imageIcon'>
);

export type CategoryDetailFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'name' | 'urlKey' | 'parentId' | 'metaTitle' | 'metaKeyword' | 'metaDescription' | 'flashSale' | 'imageIcon'>
);

export type DistrictFragment = (
  { __typename?: 'District' }
  & Pick<District, 'id' | 'provinceId' | 'name' | 'type'>
);

export type HomepageFragmentFragment = (
  { __typename?: 'Homepage' }
  & Pick<Homepage, 'id' | 'content' | 'code' | 'publishedAt' | 'createdAt' | 'updatedAt'>
);

export type OrderDetailBillingFragment = (
  { __typename?: 'OrderDetailBilling' }
  & Pick<OrderDetailBilling, 'companyName' | 'companyTax' | 'companyAddress'>
);

export type OrderProductFragment = (
  { __typename?: 'OrderProduct' }
  & Pick<OrderProduct, 'id' | 'productName' | 'image' | 'typePrice' | 'quantity' | 'totalPrice' | 'isContactProduct' | 'discountPrice' | 'isGift'>
);

export type OrderDetailShippingFragment = (
  { __typename?: 'OrderDetailShipping' }
  & Pick<OrderDetailShipping, 'name' | 'address' | 'phone'>
);

export type OrderDetailFragment = (
  { __typename?: 'OrderDetail' }
  & Pick<OrderDetail, 'id' | 'orderCode' | 'paymentStatus' | 'paymentMethod' | 'orderStatus' | 'totalProductPrice' | 'totalDiscount' | 'totalTax' | 'shippingPrice' | 'totalPayment' | 'note'>
);

export type OrderPageFragment = (
  { __typename?: 'OrderPage' }
  & Pick<OrderPage, 'total' | 'page' | 'limit'>
);

export type OrderSearchFragment = (
  { __typename?: 'OrderSearch' }
  & Pick<OrderSearch, 'id' | 'orderStatus' | 'totalProductPrice' | 'totalDiscount' | 'totalTax' | 'shippingPrice' | 'totalPayment' | 'exportVat' | 'createdAt'>
);

export type OrderItemFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'orderCode' | 'orderStatus' | 'totalPayment' | 'createdAt' | 'products'>
);

export type PageItemFragment = (
  { __typename?: 'Page' }
  & Pick<Page, 'total' | 'offset' | 'current' | 'last' | 'from' | 'to'>
);

export type ProductAttributeFragment = (
  { __typename?: 'Attribute' }
  & Pick<Attribute, 'productCode' | 'productLength' | 'productWidth' | 'productHeight' | 'productWeight' | 'metaTitle' | 'metaKeywords' | 'metaDescription' | 'name' | 'description' | 'listPrice' | 'price' | 'image' | 'galleries'>
  & { brand?: Maybe<(
    { __typename?: 'ProductAttributeValue' }
    & Pick<ProductAttributeValue, 'id' | 'value' | 'position'>
  )> }
);

export type ProductBrandFragment = (
  { __typename?: 'ProductAttributeValue' }
  & Pick<ProductAttributeValue, 'id' | 'value' | 'position'>
);

export type ProductCategoryEntityFragment = (
  { __typename?: 'ProductCategory' }
  & Pick<ProductCategory, 'id' | 'name' | 'parentId' | 'isActive' | 'path' | 'urlKey'>
);

export type ProductItemFragment = (
  { __typename?: 'Product' }
  & Pick<Product, 'productId' | 'sku' | 'name' | 'price' | 'code' | 'status' | 'imageUrl' | 'quantity' | 'quantityAvailable' | 'hasVideo' | 'isContactProduct' | 'isInterested'>
  & { attributes?: Maybe<(
    { __typename?: 'Attribute' }
    & Pick<Attribute, 'price' | 'listPrice'>
  )> }
);

export type ProductOriginFragment = (
  { __typename?: 'ProductAttributeValue' }
  & Pick<ProductAttributeValue, 'id' | 'value' | 'position'>
);

export type ProductUnitFragment = (
  { __typename?: 'ProductAttributeValue' }
  & Pick<ProductAttributeValue, 'id' | 'value' | 'position'>
);

export type ProductEntityFragment = (
  { __typename?: 'Product' }
  & Pick<Product, 'id' | 'name' | 'productsetId' | 'sku' | 'code' | 'status' | 'inventoryType' | 'type' | 'quantity' | 'quantityAvailable' | 'minSaleQty' | 'maxSaleQty' | 'price' | 'description' | 'image' | 'imageUrl' | 'shopeeId' | 'video' | 'hasVideo' | 'isContactProduct' | 'isInterested' | 'createdBy' | 'updatedBy'>
);

export type ProvinceFragment = (
  { __typename?: 'Province' }
  & Pick<Province, 'id' | 'name' | 'type'>
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'storeId' | 'groupId' | 'code' | 'address' | 'status' | 'approved' | 'name' | 'email' | 'taxCompanyName' | 'taxCompanyAddress' | 'taxCode' | 'phone' | 'phone2' | 'phone3' | 'cart' | 'newsletter' | 'contactName' | 'createdDate' | 'unit' | 'note' | 'birthday' | 'gender' | 'loginType'>
);

export type WardFragment = (
  { __typename?: 'Ward' }
  & Pick<Ward, 'id' | 'districtId' | 'name' | 'type'>
);

export type AddCartItemMutationVariables = Exact<{
  id: Scalars['ID'];
  quantity: Scalars['Int'];
}>;


export type AddCartItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addCartItem'>
);

export type DeleteCartItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCartItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCartItem'>
);

export type UpdateCartItemMutationVariables = Exact<{
  id: Scalars['ID'];
  quantity: Scalars['Int'];
}>;


export type UpdateCartItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCartItem'>
);

export type CreateGuestOrderMutationVariables = Exact<{
  input?: Maybe<CreateGuestOrderInput>;
}>;


export type CreateGuestOrderMutation = (
  { __typename?: 'Mutation' }
  & { createGuestOrder?: Maybe<(
    { __typename?: 'OrderResponse' }
    & Pick<OrderResponse, 'id' | 'orderCode'>
  )> }
);

export type CreateOrderMutationVariables = Exact<{
  input?: Maybe<CreateOrderInput>;
}>;


export type CreateOrderMutation = (
  { __typename?: 'Mutation' }
  & { createOrder?: Maybe<(
    { __typename?: 'OrderCreationResponse' }
    & Pick<OrderCreationResponse, 'success' | 'message'>
    & { response?: Maybe<(
      { __typename?: 'OrderResponse' }
      & Pick<OrderResponse, 'id' | 'orderCode'>
    )>, errorProduct?: Maybe<Array<Maybe<(
      { __typename?: 'OrderCreationErrorProduct' }
      & Pick<OrderCreationErrorProduct, 'productId' | 'name' | 'quantity'>
    )>>> }
  )> }
);

export type CartQueryVariables = Exact<{ [key: string]: never; }>;


export type CartQuery = (
  { __typename?: 'Query' }
  & { cart?: Maybe<Array<Maybe<(
    { __typename?: 'Cart' }
    & Pick<Cart, 'id' | 'quantity' | 'actualQuantity' | 'name' | 'image' | 'price' | 'listPrice' | 'status' | 'disabled' | 'isContactProduct'>
  )>>> }
);

export type CategoryProductListingQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
}>;


export type CategoryProductListingQuery = (
  { __typename?: 'Query' }
  & { category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'urlKey' | 'parentId' | 'metaTitle' | 'metaKeyword' | 'metaDescription' | 'flashSale' | 'imageIcon' | 'bannerUrl' | 'bannerThumbnailUrl'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'sku' | 'price' | 'image' | 'quantity' | 'quantityAvailable' | 'hasVideo' | 'isContactProduct' | 'isInterested'>
      & { attributes?: Maybe<(
        { __typename?: 'Attribute' }
        & Pick<Attribute, 'price' | 'listPrice'>
      )> }
    )>>> }
  )> }
);

export type CheckoutQueryVariables = Exact<{
  input?: Maybe<CheckoutInput>;
}>;


export type CheckoutQuery = (
  { __typename?: 'Query' }
  & { checkout?: Maybe<(
    { __typename?: 'Checkout' }
    & { cart?: Maybe<Array<Maybe<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'id' | 'quantity' | 'actualQuantity' | 'name' | 'image' | 'price' | 'listPrice' | 'status' | 'disabled' | 'isContactProduct'>
    )>>>, promotion?: Maybe<(
      { __typename?: 'CheckoutSimulationPromotion' }
      & Pick<CheckoutSimulationPromotion, 'totalProductPrice' | 'totalPrice' | 'totalDiscount' | 'totalTax' | 'totalPayment' | 'discountType' | 'discountValue'>
      & { discount?: Maybe<Array<Maybe<(
        { __typename?: 'CheckoutSimulationDiscount' }
        & Pick<CheckoutSimulationDiscount, 'discountManagementId' | 'minPurchase' | 'maxPurchase' | 'typeMinPurchase' | 'typeMaxPurchase' | 'name' | 'discountPercent' | 'canApplyDiscount'>
      )>>>, gift?: Maybe<Array<Maybe<(
        { __typename?: 'CheckoutSimulationGift' }
        & Pick<CheckoutSimulationGift, 'id' | 'name' | 'image'>
      )>>>, taxPercent?: Maybe<Array<Maybe<(
        { __typename?: 'CheckoutSimulationTax' }
        & Pick<CheckoutSimulationTax, 'productId' | 'value'>
      )>>> }
    )> }
  )> }
);

export type HomepageQueryVariables = Exact<{ [key: string]: never; }>;


export type HomepageQuery = (
  { __typename?: 'Query' }
  & { homepage?: Maybe<(
    { __typename?: 'Homepage' }
    & Pick<Homepage, 'id' | 'content' | 'code' | 'publishedAt' | 'createdAt' | 'updatedAt'>
  )> }
);

export type SearchKeywordQueryVariables = Exact<{
  pattern?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type SearchKeywordQuery = (
  { __typename?: 'Query' }
  & { data?: Maybe<Array<Maybe<(
    { __typename?: 'KeywordFuse' }
    & Pick<KeywordFuse, 'refIndex' | 'score'>
    & { item?: Maybe<(
      { __typename?: 'Keyword' }
      & Pick<Keyword, 'title' | 'slug' | 'link' | 'keyword'>
    )> }
  )>>> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'storeId' | 'groupId' | 'code' | 'address' | 'status' | 'approved' | 'name' | 'email' | 'taxCompanyName' | 'taxCompanyAddress' | 'taxCode' | 'phone' | 'phone2' | 'phone3' | 'cart' | 'newsletter' | 'contactName' | 'createdDate' | 'unit' | 'note' | 'birthday' | 'gender' | 'loginType'>
  )> }
);

export type SearchProductQueryVariables = Exact<{
  filter?: Maybe<SearchProductFilter>;
}>;


export type SearchProductQuery = (
  { __typename?: 'Query' }
  & { searchProductV2?: Maybe<(
    { __typename?: 'ProductResult' }
    & { products?: Maybe<Array<Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'productId' | 'sku' | 'name' | 'price' | 'code' | 'status' | 'imageUrl' | 'quantity' | 'quantityAvailable' | 'hasVideo' | 'isContactProduct' | 'isInterested'>
      & { attributes?: Maybe<(
        { __typename?: 'Attribute' }
        & Pick<Attribute, 'price' | 'listPrice'>
      )> }
    )>>>, page?: Maybe<(
      { __typename?: 'Page' }
      & Pick<Page, 'total' | 'offset' | 'current' | 'last' | 'from' | 'to'>
    )> }
  )> }
);

export type SearchProductByCategoryQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Int']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type SearchProductByCategoryQuery = (
  { __typename?: 'Query' }
  & { searchProduct?: Maybe<(
    { __typename?: 'ProductResult' }
    & { products?: Maybe<Array<Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'productId' | 'sku' | 'name' | 'price' | 'code' | 'status' | 'imageUrl' | 'quantity' | 'quantityAvailable' | 'hasVideo' | 'isContactProduct' | 'isInterested'>
      & { attributes?: Maybe<(
        { __typename?: 'Attribute' }
        & Pick<Attribute, 'price' | 'listPrice'>
      )> }
    )>>>, page?: Maybe<(
      { __typename?: 'Page' }
      & Pick<Page, 'total' | 'offset' | 'current' | 'last' | 'from' | 'to'>
    )> }
  )> }
);

export type SearchProductByIdsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  ids?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  status?: Maybe<Scalars['String']>;
}>;


export type SearchProductByIdsQuery = (
  { __typename?: 'Query' }
  & { searchProduct?: Maybe<(
    { __typename?: 'ProductResult' }
    & { products?: Maybe<Array<Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'productId' | 'sku' | 'name' | 'price' | 'code' | 'status' | 'imageUrl' | 'quantity' | 'quantityAvailable' | 'hasVideo' | 'isContactProduct' | 'isInterested'>
      & { attributes?: Maybe<(
        { __typename?: 'Attribute' }
        & Pick<Attribute, 'price' | 'listPrice' | 'googleIndex'>
      )> }
    )>>>, page?: Maybe<(
      { __typename?: 'Page' }
      & Pick<Page, 'total' | 'offset' | 'current' | 'last' | 'from' | 'to'>
    )> }
  )> }
);

export type SearchProductByCustomerViewQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
}>;


export type SearchProductByCustomerViewQuery = (
  { __typename?: 'Query' }
  & { searchProduct?: Maybe<(
    { __typename?: 'ProductResult' }
    & { products?: Maybe<Array<Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'productId' | 'sku' | 'name' | 'price' | 'code' | 'status' | 'imageUrl' | 'quantity' | 'quantityAvailable' | 'hasVideo' | 'isContactProduct' | 'isInterested'>
      & { attributes?: Maybe<(
        { __typename?: 'Attribute' }
        & Pick<Attribute, 'price' | 'listPrice'>
      )> }
    )>>>, page?: Maybe<(
      { __typename?: 'Page' }
      & Pick<Page, 'total' | 'offset' | 'current' | 'last' | 'from' | 'to'>
    )> }
  )> }
);

export type SimulatePromotionQueryVariables = Exact<{
  input?: Maybe<SimulatePromotionInput>;
}>;


export type SimulatePromotionQuery = (
  { __typename?: 'Query' }
  & { simulatePromotion?: Maybe<(
    { __typename?: 'SimulatePromotion' }
    & { discount?: Maybe<Array<Maybe<(
      { __typename?: 'SimulateDiscount' }
      & Pick<SimulateDiscount, 'discountManagementId' | 'minPurchase' | 'maxPurchase' | 'typeMinPurchase' | 'typeMaxPurchase' | 'name' | 'discountPercent' | 'canApplyDiscount'>
    )>>>, gift?: Maybe<Array<Maybe<(
      { __typename?: 'SimulateGift' }
      & Pick<SimulateGift, 'id' | 'name' | 'image'>
    )>>> }
  )> }
);

export type SimulateOrderQueryVariables = Exact<{
  input?: Maybe<SimulateOrderInput>;
}>;


export type SimulateOrderQuery = (
  { __typename?: 'Query' }
  & { simulateOrder?: Maybe<(
    { __typename?: 'SimulateOrder' }
    & Pick<SimulateOrder, 'discountManagementId' | 'minPurchase' | 'maxPurchase' | 'typeMinPurchase' | 'typeMaxPurchase' | 'name' | 'discountPercent' | 'canApplyDiscount'>
  )> }
);

export type SearchSuggestKeywordsQueryVariables = Exact<{
  filter?: Maybe<SuggestKeywordFilter>;
}>;


export type SearchSuggestKeywordsQuery = (
  { __typename?: 'Query' }
  & { data?: Maybe<Array<Maybe<(
    { __typename?: 'SuggestKeyword' }
    & Pick<SuggestKeyword, 'id' | 'keyword' | 'image' | 'note' | 'status' | 'url'>
  )>>> }
);

export type CategoryQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
}>;


export type CategoryQuery = (
  { __typename?: 'Query' }
  & { categories?: Maybe<Array<Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'parentId' | 'path' | 'urlKey' | 'flashSale' | 'imageIcon'>
  )>>> }
);

export type BrandQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
}>;


export type BrandQuery = (
  { __typename?: 'Query' }
  & { data?: Maybe<(
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'name'>
  )> }
);

export type SearchProductByBrandQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['Int']>;
}>;


export type SearchProductByBrandQuery = (
  { __typename?: 'Query' }
  & { data?: Maybe<(
    { __typename?: 'ProductResult' }
    & { products?: Maybe<Array<Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'productId' | 'sku' | 'name' | 'price' | 'code' | 'status' | 'imageUrl' | 'quantity' | 'quantityAvailable' | 'hasVideo' | 'isContactProduct' | 'isInterested'>
      & { attributes?: Maybe<(
        { __typename?: 'Attribute' }
        & Pick<Attribute, 'price' | 'listPrice'>
      )> }
    )>>>, page?: Maybe<(
      { __typename?: 'Page' }
      & Pick<Page, 'total' | 'offset' | 'current' | 'last' | 'from' | 'to'>
    )> }
  )> }
);

export type CategoryTreeQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
}>;


export type CategoryTreeQuery = (
  { __typename?: 'Query' }
  & { data?: Maybe<Array<Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'parentId' | 'isActive' | 'urlKey' | 'path'>
  )>>> }
);

export type CategoryDetailQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
}>;


export type CategoryDetailQuery = (
  { __typename?: 'Query' }
  & { category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'urlKey' | 'parentId' | 'metaTitle' | 'metaKeyword' | 'metaDescription' | 'flashSale' | 'imageIcon' | 'bannerUrl' | 'bannerThumbnailUrl' | 'description' | 'longDescription'>
  )> }
);

export type ProductWithReviewQueryVariables = Exact<{
  id: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ProductWithReviewQuery = (
  { __typename?: 'Query' }
  & { data?: Maybe<(
    { __typename?: 'ProductDetailWithReview' }
    & { product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'productId' | 'name' | 'productsetId' | 'sku' | 'code' | 'status' | 'inventoryType' | 'type' | 'quantity' | 'quantityAvailable' | 'minSaleQty' | 'maxSaleQty' | 'price' | 'description' | 'image' | 'imageUrl' | 'createdBy' | 'updatedBy' | 'shopeeId' | 'video' | 'hasVideo' | 'isContactProduct' | 'isInterested'>
      & { attributes?: Maybe<(
        { __typename?: 'Attribute' }
        & Pick<Attribute, 'productCode' | 'productLength' | 'productWidth' | 'productHeight' | 'productWeight' | 'metaTitle' | 'metaKeywords' | 'metaDescription' | 'name' | 'description' | 'listPrice' | 'price' | 'image' | 'galleries' | 'googleIndex' | 'canViewRating' | 'sanphamcobaogia'>
        & { brand?: Maybe<(
          { __typename?: 'ProductAttributeValue' }
          & Pick<ProductAttributeValue, 'id' | 'value' | 'position'>
        )> }
      )>, categories?: Maybe<Array<Maybe<(
        { __typename?: 'ProductCategory' }
        & Pick<ProductCategory, 'id' | 'name' | 'parentId' | 'isActive' | 'path' | 'urlKey'>
      )>>> }
    )>, summary?: Maybe<(
      { __typename?: 'ShopeeProductRatingSummary' }
      & Pick<ShopeeProductRatingSummary, 'total' | 'ratingCount'>
    )>, ratings?: Maybe<Array<Maybe<(
      { __typename?: 'ShopeeProductRatingItem' }
      & Pick<ShopeeProductRatingItem, 'itemid' | 'ratingStar' | 'time' | 'comment' | 'images' | 'tags' | 'author' | 'authorLink' | 'authorPortrait'>
    )>>> }
  )> }
);

export const AddressItemFragmentDoc = gql`
    fragment AddressItem on Address {
  id
  customerId
  contactName
  email
  phone
  default
  address
  ward
  district
  province
  fullAddress
}
    `;
export const CartItemFragmentDoc = gql`
    fragment CartItem on Cart {
  id
  quantity
  actualQuantity
  name
  image
  price
  listPrice
  status
  disabled
  isContactProduct
}
    `;
export const CategoryItemFragmentDoc = gql`
    fragment CategoryItem on Category {
  id
  name
  parentId
  path
  urlKey
  flashSale
  imageIcon
}
    `;
export const CategoryDetailFragmentDoc = gql`
    fragment CategoryDetail on Category {
  id
  name
  urlKey
  parentId
  metaTitle
  metaKeyword
  metaDescription
  flashSale
  imageIcon
}
    `;
export const DistrictFragmentDoc = gql`
    fragment District on District {
  id
  provinceId
  name
  type
}
    `;
export const HomepageFragmentFragmentDoc = gql`
    fragment HomepageFragment on Homepage {
  id
  content
  code
  publishedAt
  createdAt
  updatedAt
}
    `;
export const OrderDetailBillingFragmentDoc = gql`
    fragment OrderDetailBilling on OrderDetailBilling {
  companyName
  companyTax
  companyAddress
}
    `;
export const OrderProductFragmentDoc = gql`
    fragment OrderProduct on OrderProduct {
  id
  productName
  image
  typePrice
  quantity
  totalPrice
  isContactProduct
  discountPrice
  isGift
}
    `;
export const OrderDetailShippingFragmentDoc = gql`
    fragment OrderDetailShipping on OrderDetailShipping {
  name
  address
  phone
}
    `;
export const OrderDetailFragmentDoc = gql`
    fragment OrderDetail on OrderDetail {
  id
  orderCode
  paymentStatus
  paymentMethod
  orderStatus
  totalProductPrice
  totalDiscount
  totalTax
  shippingPrice
  totalPayment
  note
}
    `;
export const OrderPageFragmentDoc = gql`
    fragment OrderPage on OrderPage {
  total
  page
  limit
}
    `;
export const OrderSearchFragmentDoc = gql`
    fragment OrderSearch on OrderSearch {
  id
  orderStatus
  totalProductPrice
  totalDiscount
  totalTax
  shippingPrice
  totalPayment
  exportVat
  createdAt
}
    `;
export const OrderItemFragmentDoc = gql`
    fragment OrderItem on Order {
  id
  orderCode
  orderStatus
  totalPayment
  createdAt
  products
}
    `;
export const PageItemFragmentDoc = gql`
    fragment PageItem on Page {
  total
  offset
  current
  last
  from
  to
}
    `;
export const ProductAttributeFragmentDoc = gql`
    fragment ProductAttribute on Attribute {
  productCode
  productLength
  productWidth
  productHeight
  productWeight
  metaTitle
  metaKeywords
  metaDescription
  name
  description
  listPrice
  price
  image
  galleries
  brand {
    id
    value
    position
  }
}
    `;
export const ProductBrandFragmentDoc = gql`
    fragment ProductBrand on ProductAttributeValue {
  id
  value
  position
}
    `;
export const ProductCategoryEntityFragmentDoc = gql`
    fragment ProductCategoryEntity on ProductCategory {
  id
  name
  parentId
  isActive
  path
  urlKey
}
    `;
export const ProductItemFragmentDoc = gql`
    fragment ProductItem on Product {
  productId
  sku
  name
  price
  code
  status
  imageUrl
  quantity
  quantityAvailable
  hasVideo
  isContactProduct
  isInterested
  attributes {
    price
    listPrice
  }
}
    `;
export const ProductOriginFragmentDoc = gql`
    fragment ProductOrigin on ProductAttributeValue {
  id
  value
  position
}
    `;
export const ProductUnitFragmentDoc = gql`
    fragment ProductUnit on ProductAttributeValue {
  id
  value
  position
}
    `;
export const ProductEntityFragmentDoc = gql`
    fragment ProductEntity on Product {
  id
  name
  productsetId
  sku
  code
  status
  inventoryType
  type
  quantity
  quantityAvailable
  minSaleQty
  maxSaleQty
  price
  description
  image
  imageUrl
  shopeeId
  video
  hasVideo
  isContactProduct
  isInterested
  createdBy
  updatedBy
}
    `;
export const ProvinceFragmentDoc = gql`
    fragment Province on Province {
  id
  name
  type
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  storeId
  groupId
  code
  address
  status
  approved
  name
  email
  taxCompanyName
  taxCompanyAddress
  taxCode
  phone
  phone2
  phone3
  cart
  newsletter
  contactName
  createdDate
  unit
  note
  birthday
  gender
  loginType
}
    `;
export const WardFragmentDoc = gql`
    fragment Ward on Ward {
  id
  districtId
  name
  type
}
    `;
export const AddCartItemDocument = gql`
    mutation AddCartItem($id: ID!, $quantity: Int!) {
  addCartItem(id: $id, quantity: $quantity)
}
    `;
export type AddCartItemMutationFn = Apollo.MutationFunction<AddCartItemMutation, AddCartItemMutationVariables>;
export type AddCartItemProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<AddCartItemMutation, AddCartItemMutationVariables>
    } & TChildProps;
export function withAddCartItem<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddCartItemMutation,
  AddCartItemMutationVariables,
  AddCartItemProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddCartItemMutation, AddCartItemMutationVariables, AddCartItemProps<TChildProps, TDataName>>(AddCartItemDocument, {
      alias: 'addCartItem',
      ...operationOptions
    });
};

/**
 * __useAddCartItemMutation__
 *
 * To run a mutation, you first call `useAddCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCartItemMutation, { data, loading, error }] = useAddCartItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useAddCartItemMutation(baseOptions?: Apollo.MutationHookOptions<AddCartItemMutation, AddCartItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCartItemMutation, AddCartItemMutationVariables>(AddCartItemDocument, options);
      }
export type AddCartItemMutationHookResult = ReturnType<typeof useAddCartItemMutation>;
export type AddCartItemMutationResult = Apollo.MutationResult<AddCartItemMutation>;
export type AddCartItemMutationOptions = Apollo.BaseMutationOptions<AddCartItemMutation, AddCartItemMutationVariables>;
export const DeleteCartItemDocument = gql`
    mutation DeleteCartItem($id: ID!) {
  deleteCartItem(id: $id)
}
    `;
export type DeleteCartItemMutationFn = Apollo.MutationFunction<DeleteCartItemMutation, DeleteCartItemMutationVariables>;
export type DeleteCartItemProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<DeleteCartItemMutation, DeleteCartItemMutationVariables>
    } & TChildProps;
export function withDeleteCartItem<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteCartItemMutation,
  DeleteCartItemMutationVariables,
  DeleteCartItemProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteCartItemMutation, DeleteCartItemMutationVariables, DeleteCartItemProps<TChildProps, TDataName>>(DeleteCartItemDocument, {
      alias: 'deleteCartItem',
      ...operationOptions
    });
};

/**
 * __useDeleteCartItemMutation__
 *
 * To run a mutation, you first call `useDeleteCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCartItemMutation, { data, loading, error }] = useDeleteCartItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCartItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCartItemMutation, DeleteCartItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCartItemMutation, DeleteCartItemMutationVariables>(DeleteCartItemDocument, options);
      }
export type DeleteCartItemMutationHookResult = ReturnType<typeof useDeleteCartItemMutation>;
export type DeleteCartItemMutationResult = Apollo.MutationResult<DeleteCartItemMutation>;
export type DeleteCartItemMutationOptions = Apollo.BaseMutationOptions<DeleteCartItemMutation, DeleteCartItemMutationVariables>;
export const UpdateCartItemDocument = gql`
    mutation UpdateCartItem($id: ID!, $quantity: Int!) {
  updateCartItem(id: $id, quantity: $quantity)
}
    `;
export type UpdateCartItemMutationFn = Apollo.MutationFunction<UpdateCartItemMutation, UpdateCartItemMutationVariables>;
export type UpdateCartItemProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<UpdateCartItemMutation, UpdateCartItemMutationVariables>
    } & TChildProps;
export function withUpdateCartItem<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCartItemMutation,
  UpdateCartItemMutationVariables,
  UpdateCartItemProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCartItemMutation, UpdateCartItemMutationVariables, UpdateCartItemProps<TChildProps, TDataName>>(UpdateCartItemDocument, {
      alias: 'updateCartItem',
      ...operationOptions
    });
};

/**
 * __useUpdateCartItemMutation__
 *
 * To run a mutation, you first call `useUpdateCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartItemMutation, { data, loading, error }] = useUpdateCartItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useUpdateCartItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCartItemMutation, UpdateCartItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCartItemMutation, UpdateCartItemMutationVariables>(UpdateCartItemDocument, options);
      }
export type UpdateCartItemMutationHookResult = ReturnType<typeof useUpdateCartItemMutation>;
export type UpdateCartItemMutationResult = Apollo.MutationResult<UpdateCartItemMutation>;
export type UpdateCartItemMutationOptions = Apollo.BaseMutationOptions<UpdateCartItemMutation, UpdateCartItemMutationVariables>;
export const CreateGuestOrderDocument = gql`
    mutation CreateGuestOrder($input: CreateGuestOrderInput) {
  createGuestOrder(input: $input) {
    id
    orderCode
  }
}
    `;
export type CreateGuestOrderMutationFn = Apollo.MutationFunction<CreateGuestOrderMutation, CreateGuestOrderMutationVariables>;
export type CreateGuestOrderProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<CreateGuestOrderMutation, CreateGuestOrderMutationVariables>
    } & TChildProps;
export function withCreateGuestOrder<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateGuestOrderMutation,
  CreateGuestOrderMutationVariables,
  CreateGuestOrderProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateGuestOrderMutation, CreateGuestOrderMutationVariables, CreateGuestOrderProps<TChildProps, TDataName>>(CreateGuestOrderDocument, {
      alias: 'createGuestOrder',
      ...operationOptions
    });
};

/**
 * __useCreateGuestOrderMutation__
 *
 * To run a mutation, you first call `useCreateGuestOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGuestOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGuestOrderMutation, { data, loading, error }] = useCreateGuestOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGuestOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateGuestOrderMutation, CreateGuestOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGuestOrderMutation, CreateGuestOrderMutationVariables>(CreateGuestOrderDocument, options);
      }
export type CreateGuestOrderMutationHookResult = ReturnType<typeof useCreateGuestOrderMutation>;
export type CreateGuestOrderMutationResult = Apollo.MutationResult<CreateGuestOrderMutation>;
export type CreateGuestOrderMutationOptions = Apollo.BaseMutationOptions<CreateGuestOrderMutation, CreateGuestOrderMutationVariables>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($input: CreateOrderInput) {
  createOrder(input: $input) {
    success
    response {
      id
      orderCode
    }
    errorProduct {
      productId
      name
      quantity
    }
    message
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;
export type CreateOrderProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>
    } & TChildProps;
export function withCreateOrder<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateOrderMutation,
  CreateOrderMutationVariables,
  CreateOrderProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateOrderMutation, CreateOrderMutationVariables, CreateOrderProps<TChildProps, TDataName>>(CreateOrderDocument, {
      alias: 'createOrder',
      ...operationOptions
    });
};

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const CartDocument = gql`
    query Cart {
  cart {
    id
    quantity
    actualQuantity
    name
    image
    price
    listPrice
    status
    disabled
    isContactProduct
  }
}
    `;
export type CartProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CartQuery, CartQueryVariables>
    } & TChildProps;
export function withCart<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CartQuery,
  CartQueryVariables,
  CartProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CartQuery, CartQueryVariables, CartProps<TChildProps, TDataName>>(CartDocument, {
      alias: 'cart',
      ...operationOptions
    });
};

/**
 * __useCartQuery__
 *
 * To run a query within a React component, call `useCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartQuery({
 *   variables: {
 *   },
 * });
 */
export function useCartQuery(baseOptions?: Apollo.QueryHookOptions<CartQuery, CartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CartQuery, CartQueryVariables>(CartDocument, options);
      }
export function useCartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CartQuery, CartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CartQuery, CartQueryVariables>(CartDocument, options);
        }
export type CartQueryHookResult = ReturnType<typeof useCartQuery>;
export type CartLazyQueryHookResult = ReturnType<typeof useCartLazyQuery>;
export type CartQueryResult = Apollo.QueryResult<CartQuery, CartQueryVariables>;
export const CategoryProductListingDocument = gql`
    query CategoryProductListing($id: Int, $page: Int, $limit: Int, $orderBy: String) {
  category(id: $id, page: $page, limit: $limit, orderBy: $orderBy) {
    id
    name
    urlKey
    parentId
    metaTitle
    metaKeyword
    metaDescription
    flashSale
    imageIcon
    bannerUrl
    bannerThumbnailUrl
    items {
      id
      name
      sku
      price
      image
      quantity
      quantityAvailable
      hasVideo
      isContactProduct
      isInterested
      attributes {
        price
        listPrice
      }
    }
  }
}
    `;
export type CategoryProductListingProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CategoryProductListingQuery, CategoryProductListingQueryVariables>
    } & TChildProps;
export function withCategoryProductListing<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CategoryProductListingQuery,
  CategoryProductListingQueryVariables,
  CategoryProductListingProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CategoryProductListingQuery, CategoryProductListingQueryVariables, CategoryProductListingProps<TChildProps, TDataName>>(CategoryProductListingDocument, {
      alias: 'categoryProductListing',
      ...operationOptions
    });
};

/**
 * __useCategoryProductListingQuery__
 *
 * To run a query within a React component, call `useCategoryProductListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryProductListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryProductListingQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCategoryProductListingQuery(baseOptions?: Apollo.QueryHookOptions<CategoryProductListingQuery, CategoryProductListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryProductListingQuery, CategoryProductListingQueryVariables>(CategoryProductListingDocument, options);
      }
export function useCategoryProductListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryProductListingQuery, CategoryProductListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryProductListingQuery, CategoryProductListingQueryVariables>(CategoryProductListingDocument, options);
        }
export type CategoryProductListingQueryHookResult = ReturnType<typeof useCategoryProductListingQuery>;
export type CategoryProductListingLazyQueryHookResult = ReturnType<typeof useCategoryProductListingLazyQuery>;
export type CategoryProductListingQueryResult = Apollo.QueryResult<CategoryProductListingQuery, CategoryProductListingQueryVariables>;
export const CheckoutDocument = gql`
    query Checkout($input: CheckoutInput) {
  checkout(input: $input) {
    cart {
      id
      quantity
      actualQuantity
      name
      image
      price
      listPrice
      status
      disabled
      isContactProduct
    }
    promotion {
      discount {
        discountManagementId
        minPurchase
        maxPurchase
        typeMinPurchase
        typeMaxPurchase
        name
        discountPercent
        canApplyDiscount
      }
      gift {
        id
        name
        image
      }
      taxPercent {
        productId
        value
      }
      totalProductPrice
      totalPrice
      totalDiscount
      totalTax
      totalPayment
      discountType
      discountValue
    }
  }
}
    `;
export type CheckoutProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CheckoutQuery, CheckoutQueryVariables>
    } & TChildProps;
export function withCheckout<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CheckoutQuery,
  CheckoutQueryVariables,
  CheckoutProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CheckoutQuery, CheckoutQueryVariables, CheckoutProps<TChildProps, TDataName>>(CheckoutDocument, {
      alias: 'checkout',
      ...operationOptions
    });
};

/**
 * __useCheckoutQuery__
 *
 * To run a query within a React component, call `useCheckoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckoutQuery(baseOptions?: Apollo.QueryHookOptions<CheckoutQuery, CheckoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckoutQuery, CheckoutQueryVariables>(CheckoutDocument, options);
      }
export function useCheckoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckoutQuery, CheckoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckoutQuery, CheckoutQueryVariables>(CheckoutDocument, options);
        }
export type CheckoutQueryHookResult = ReturnType<typeof useCheckoutQuery>;
export type CheckoutLazyQueryHookResult = ReturnType<typeof useCheckoutLazyQuery>;
export type CheckoutQueryResult = Apollo.QueryResult<CheckoutQuery, CheckoutQueryVariables>;
export const HomepageDocument = gql`
    query Homepage {
  homepage {
    id
    content
    code
    publishedAt
    createdAt
    updatedAt
  }
}
    `;
export type HomepageProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<HomepageQuery, HomepageQueryVariables>
    } & TChildProps;
export function withHomepage<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  HomepageQuery,
  HomepageQueryVariables,
  HomepageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, HomepageQuery, HomepageQueryVariables, HomepageProps<TChildProps, TDataName>>(HomepageDocument, {
      alias: 'homepage',
      ...operationOptions
    });
};

/**
 * __useHomepageQuery__
 *
 * To run a query within a React component, call `useHomepageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomepageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomepageQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomepageQuery(baseOptions?: Apollo.QueryHookOptions<HomepageQuery, HomepageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomepageQuery, HomepageQueryVariables>(HomepageDocument, options);
      }
export function useHomepageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomepageQuery, HomepageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomepageQuery, HomepageQueryVariables>(HomepageDocument, options);
        }
export type HomepageQueryHookResult = ReturnType<typeof useHomepageQuery>;
export type HomepageLazyQueryHookResult = ReturnType<typeof useHomepageLazyQuery>;
export type HomepageQueryResult = Apollo.QueryResult<HomepageQuery, HomepageQueryVariables>;
export const SearchKeywordDocument = gql`
    query SearchKeyword($pattern: String, $limit: Int) {
  data: keywords(pattern: $pattern, limit: $limit) {
    item {
      title
      slug
      link
      keyword
    }
    refIndex
    score
  }
}
    `;
export type SearchKeywordProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SearchKeywordQuery, SearchKeywordQueryVariables>
    } & TChildProps;
export function withSearchKeyword<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SearchKeywordQuery,
  SearchKeywordQueryVariables,
  SearchKeywordProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SearchKeywordQuery, SearchKeywordQueryVariables, SearchKeywordProps<TChildProps, TDataName>>(SearchKeywordDocument, {
      alias: 'searchKeyword',
      ...operationOptions
    });
};

/**
 * __useSearchKeywordQuery__
 *
 * To run a query within a React component, call `useSearchKeywordQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchKeywordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchKeywordQuery({
 *   variables: {
 *      pattern: // value for 'pattern'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchKeywordQuery(baseOptions?: Apollo.QueryHookOptions<SearchKeywordQuery, SearchKeywordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchKeywordQuery, SearchKeywordQueryVariables>(SearchKeywordDocument, options);
      }
export function useSearchKeywordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchKeywordQuery, SearchKeywordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchKeywordQuery, SearchKeywordQueryVariables>(SearchKeywordDocument, options);
        }
export type SearchKeywordQueryHookResult = ReturnType<typeof useSearchKeywordQuery>;
export type SearchKeywordLazyQueryHookResult = ReturnType<typeof useSearchKeywordLazyQuery>;
export type SearchKeywordQueryResult = Apollo.QueryResult<SearchKeywordQuery, SearchKeywordQueryVariables>;
export const MeDocument = gql`
    query ME {
  me {
    id
    storeId
    groupId
    code
    address
    status
    approved
    name
    email
    taxCompanyName
    taxCompanyAddress
    taxCode
    phone
    phone2
    phone3
    cart
    newsletter
    contactName
    createdDate
    unit
    note
    birthday
    gender
    loginType
  }
}
    `;
export type MeProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<MeQuery, MeQueryVariables>
    } & TChildProps;
export function withMe<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MeQuery,
  MeQueryVariables,
  MeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, MeQuery, MeQueryVariables, MeProps<TChildProps, TDataName>>(MeDocument, {
      alias: 'me',
      ...operationOptions
    });
};

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const SearchProductDocument = gql`
    query SearchProduct($filter: SearchProductFilter) {
  searchProductV2(filter: $filter) {
    products {
      productId
      sku
      name
      price
      code
      status
      imageUrl
      quantity
      quantityAvailable
      hasVideo
      isContactProduct
      isInterested
      attributes {
        price
        listPrice
      }
    }
    page {
      total
      offset
      current
      last
      from
      to
    }
  }
}
    `;
export type SearchProductProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SearchProductQuery, SearchProductQueryVariables>
    } & TChildProps;
export function withSearchProduct<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SearchProductQuery,
  SearchProductQueryVariables,
  SearchProductProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SearchProductQuery, SearchProductQueryVariables, SearchProductProps<TChildProps, TDataName>>(SearchProductDocument, {
      alias: 'searchProduct',
      ...operationOptions
    });
};

/**
 * __useSearchProductQuery__
 *
 * To run a query within a React component, call `useSearchProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchProductQuery(baseOptions?: Apollo.QueryHookOptions<SearchProductQuery, SearchProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchProductQuery, SearchProductQueryVariables>(SearchProductDocument, options);
      }
export function useSearchProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchProductQuery, SearchProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchProductQuery, SearchProductQueryVariables>(SearchProductDocument, options);
        }
export type SearchProductQueryHookResult = ReturnType<typeof useSearchProductQuery>;
export type SearchProductLazyQueryHookResult = ReturnType<typeof useSearchProductLazyQuery>;
export type SearchProductQueryResult = Apollo.QueryResult<SearchProductQuery, SearchProductQueryVariables>;
export const SearchProductByCategoryDocument = gql`
    query SearchProductByCategory($page: Int, $limit: Int, $orderBy: String, $categoryId: Int, $keyword: String) {
  searchProduct(page: $page, limit: $limit, orderBy: $orderBy, categoryId: $categoryId, keyword: $keyword) {
    products {
      productId
      sku
      name
      price
      code
      status
      imageUrl
      quantity
      quantityAvailable
      hasVideo
      isContactProduct
      isInterested
      attributes {
        price
        listPrice
      }
    }
    page {
      total
      offset
      current
      last
      from
      to
    }
  }
}
    `;
export type SearchProductByCategoryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SearchProductByCategoryQuery, SearchProductByCategoryQueryVariables>
    } & TChildProps;
export function withSearchProductByCategory<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SearchProductByCategoryQuery,
  SearchProductByCategoryQueryVariables,
  SearchProductByCategoryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SearchProductByCategoryQuery, SearchProductByCategoryQueryVariables, SearchProductByCategoryProps<TChildProps, TDataName>>(SearchProductByCategoryDocument, {
      alias: 'searchProductByCategory',
      ...operationOptions
    });
};

/**
 * __useSearchProductByCategoryQuery__
 *
 * To run a query within a React component, call `useSearchProductByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductByCategoryQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      categoryId: // value for 'categoryId'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useSearchProductByCategoryQuery(baseOptions?: Apollo.QueryHookOptions<SearchProductByCategoryQuery, SearchProductByCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchProductByCategoryQuery, SearchProductByCategoryQueryVariables>(SearchProductByCategoryDocument, options);
      }
export function useSearchProductByCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchProductByCategoryQuery, SearchProductByCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchProductByCategoryQuery, SearchProductByCategoryQueryVariables>(SearchProductByCategoryDocument, options);
        }
export type SearchProductByCategoryQueryHookResult = ReturnType<typeof useSearchProductByCategoryQuery>;
export type SearchProductByCategoryLazyQueryHookResult = ReturnType<typeof useSearchProductByCategoryLazyQuery>;
export type SearchProductByCategoryQueryResult = Apollo.QueryResult<SearchProductByCategoryQuery, SearchProductByCategoryQueryVariables>;
export const SearchProductByIdsDocument = gql`
    query SearchProductByIds($page: Int, $limit: Int, $ids: [Int], $status: String) {
  searchProduct(page: $page, limit: $limit, ids: $ids, status: $status) {
    products {
      productId
      sku
      name
      price
      code
      status
      imageUrl
      quantity
      quantityAvailable
      hasVideo
      isContactProduct
      isInterested
      attributes {
        price
        listPrice
        googleIndex
      }
    }
    page {
      total
      offset
      current
      last
      from
      to
    }
  }
}
    `;
export type SearchProductByIdsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SearchProductByIdsQuery, SearchProductByIdsQueryVariables>
    } & TChildProps;
export function withSearchProductByIds<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SearchProductByIdsQuery,
  SearchProductByIdsQueryVariables,
  SearchProductByIdsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SearchProductByIdsQuery, SearchProductByIdsQueryVariables, SearchProductByIdsProps<TChildProps, TDataName>>(SearchProductByIdsDocument, {
      alias: 'searchProductByIds',
      ...operationOptions
    });
};

/**
 * __useSearchProductByIdsQuery__
 *
 * To run a query within a React component, call `useSearchProductByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductByIdsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      ids: // value for 'ids'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSearchProductByIdsQuery(baseOptions?: Apollo.QueryHookOptions<SearchProductByIdsQuery, SearchProductByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchProductByIdsQuery, SearchProductByIdsQueryVariables>(SearchProductByIdsDocument, options);
      }
export function useSearchProductByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchProductByIdsQuery, SearchProductByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchProductByIdsQuery, SearchProductByIdsQueryVariables>(SearchProductByIdsDocument, options);
        }
export type SearchProductByIdsQueryHookResult = ReturnType<typeof useSearchProductByIdsQuery>;
export type SearchProductByIdsLazyQueryHookResult = ReturnType<typeof useSearchProductByIdsLazyQuery>;
export type SearchProductByIdsQueryResult = Apollo.QueryResult<SearchProductByIdsQuery, SearchProductByIdsQueryVariables>;
export const SearchProductByCustomerViewDocument = gql`
    query SearchProductByCustomerView($page: Int, $limit: Int, $customerId: ID, $status: String) {
  searchProduct(page: $page, limit: $limit, customerId: $customerId, status: $status) {
    products {
      productId
      sku
      name
      price
      code
      status
      imageUrl
      quantity
      quantityAvailable
      hasVideo
      isContactProduct
      isInterested
      attributes {
        price
        listPrice
      }
    }
    page {
      total
      offset
      current
      last
      from
      to
    }
  }
}
    `;
export type SearchProductByCustomerViewProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SearchProductByCustomerViewQuery, SearchProductByCustomerViewQueryVariables>
    } & TChildProps;
export function withSearchProductByCustomerView<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SearchProductByCustomerViewQuery,
  SearchProductByCustomerViewQueryVariables,
  SearchProductByCustomerViewProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SearchProductByCustomerViewQuery, SearchProductByCustomerViewQueryVariables, SearchProductByCustomerViewProps<TChildProps, TDataName>>(SearchProductByCustomerViewDocument, {
      alias: 'searchProductByCustomerView',
      ...operationOptions
    });
};

/**
 * __useSearchProductByCustomerViewQuery__
 *
 * To run a query within a React component, call `useSearchProductByCustomerViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductByCustomerViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductByCustomerViewQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      customerId: // value for 'customerId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSearchProductByCustomerViewQuery(baseOptions?: Apollo.QueryHookOptions<SearchProductByCustomerViewQuery, SearchProductByCustomerViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchProductByCustomerViewQuery, SearchProductByCustomerViewQueryVariables>(SearchProductByCustomerViewDocument, options);
      }
export function useSearchProductByCustomerViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchProductByCustomerViewQuery, SearchProductByCustomerViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchProductByCustomerViewQuery, SearchProductByCustomerViewQueryVariables>(SearchProductByCustomerViewDocument, options);
        }
export type SearchProductByCustomerViewQueryHookResult = ReturnType<typeof useSearchProductByCustomerViewQuery>;
export type SearchProductByCustomerViewLazyQueryHookResult = ReturnType<typeof useSearchProductByCustomerViewLazyQuery>;
export type SearchProductByCustomerViewQueryResult = Apollo.QueryResult<SearchProductByCustomerViewQuery, SearchProductByCustomerViewQueryVariables>;
export const SimulatePromotionDocument = gql`
    query SimulatePromotion($input: SimulatePromotionInput) {
  simulatePromotion(input: $input) {
    discount {
      discountManagementId
      minPurchase
      maxPurchase
      typeMinPurchase
      typeMaxPurchase
      name
      discountPercent
      canApplyDiscount
    }
    gift {
      id
      name
      image
    }
  }
}
    `;
export type SimulatePromotionProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SimulatePromotionQuery, SimulatePromotionQueryVariables>
    } & TChildProps;
export function withSimulatePromotion<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SimulatePromotionQuery,
  SimulatePromotionQueryVariables,
  SimulatePromotionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SimulatePromotionQuery, SimulatePromotionQueryVariables, SimulatePromotionProps<TChildProps, TDataName>>(SimulatePromotionDocument, {
      alias: 'simulatePromotion',
      ...operationOptions
    });
};

/**
 * __useSimulatePromotionQuery__
 *
 * To run a query within a React component, call `useSimulatePromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimulatePromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimulatePromotionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSimulatePromotionQuery(baseOptions?: Apollo.QueryHookOptions<SimulatePromotionQuery, SimulatePromotionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimulatePromotionQuery, SimulatePromotionQueryVariables>(SimulatePromotionDocument, options);
      }
export function useSimulatePromotionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimulatePromotionQuery, SimulatePromotionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimulatePromotionQuery, SimulatePromotionQueryVariables>(SimulatePromotionDocument, options);
        }
export type SimulatePromotionQueryHookResult = ReturnType<typeof useSimulatePromotionQuery>;
export type SimulatePromotionLazyQueryHookResult = ReturnType<typeof useSimulatePromotionLazyQuery>;
export type SimulatePromotionQueryResult = Apollo.QueryResult<SimulatePromotionQuery, SimulatePromotionQueryVariables>;
export const SimulateOrderDocument = gql`
    query SimulateOrder($input: SimulateOrderInput) {
  simulateOrder(input: $input) {
    discountManagementId
    minPurchase
    maxPurchase
    typeMinPurchase
    typeMaxPurchase
    name
    discountPercent
    canApplyDiscount
  }
}
    `;
export type SimulateOrderProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SimulateOrderQuery, SimulateOrderQueryVariables>
    } & TChildProps;
export function withSimulateOrder<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SimulateOrderQuery,
  SimulateOrderQueryVariables,
  SimulateOrderProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SimulateOrderQuery, SimulateOrderQueryVariables, SimulateOrderProps<TChildProps, TDataName>>(SimulateOrderDocument, {
      alias: 'simulateOrder',
      ...operationOptions
    });
};

/**
 * __useSimulateOrderQuery__
 *
 * To run a query within a React component, call `useSimulateOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimulateOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimulateOrderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSimulateOrderQuery(baseOptions?: Apollo.QueryHookOptions<SimulateOrderQuery, SimulateOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimulateOrderQuery, SimulateOrderQueryVariables>(SimulateOrderDocument, options);
      }
export function useSimulateOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimulateOrderQuery, SimulateOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimulateOrderQuery, SimulateOrderQueryVariables>(SimulateOrderDocument, options);
        }
export type SimulateOrderQueryHookResult = ReturnType<typeof useSimulateOrderQuery>;
export type SimulateOrderLazyQueryHookResult = ReturnType<typeof useSimulateOrderLazyQuery>;
export type SimulateOrderQueryResult = Apollo.QueryResult<SimulateOrderQuery, SimulateOrderQueryVariables>;
export const SearchSuggestKeywordsDocument = gql`
    query SearchSuggestKeywords($filter: SuggestKeywordFilter) {
  data: suggestKeywords(filter: $filter) {
    id
    keyword
    image
    note
    status
    url
  }
}
    `;
export type SearchSuggestKeywordsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SearchSuggestKeywordsQuery, SearchSuggestKeywordsQueryVariables>
    } & TChildProps;
export function withSearchSuggestKeywords<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SearchSuggestKeywordsQuery,
  SearchSuggestKeywordsQueryVariables,
  SearchSuggestKeywordsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SearchSuggestKeywordsQuery, SearchSuggestKeywordsQueryVariables, SearchSuggestKeywordsProps<TChildProps, TDataName>>(SearchSuggestKeywordsDocument, {
      alias: 'searchSuggestKeywords',
      ...operationOptions
    });
};

/**
 * __useSearchSuggestKeywordsQuery__
 *
 * To run a query within a React component, call `useSearchSuggestKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSuggestKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSuggestKeywordsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchSuggestKeywordsQuery(baseOptions?: Apollo.QueryHookOptions<SearchSuggestKeywordsQuery, SearchSuggestKeywordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchSuggestKeywordsQuery, SearchSuggestKeywordsQueryVariables>(SearchSuggestKeywordsDocument, options);
      }
export function useSearchSuggestKeywordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchSuggestKeywordsQuery, SearchSuggestKeywordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchSuggestKeywordsQuery, SearchSuggestKeywordsQueryVariables>(SearchSuggestKeywordsDocument, options);
        }
export type SearchSuggestKeywordsQueryHookResult = ReturnType<typeof useSearchSuggestKeywordsQuery>;
export type SearchSuggestKeywordsLazyQueryHookResult = ReturnType<typeof useSearchSuggestKeywordsLazyQuery>;
export type SearchSuggestKeywordsQueryResult = Apollo.QueryResult<SearchSuggestKeywordsQuery, SearchSuggestKeywordsQueryVariables>;
export const CategoryDocument = gql`
    query Category($page: Int, $limit: Int, $orderBy: String, $parentId: Int) {
  categories: categoryFilterQuery(page: $page, limit: $limit, orderBy: $orderBy, parentId: $parentId) {
    id
    name
    parentId
    path
    urlKey
    flashSale
    imageIcon
  }
}
    `;
export type CategoryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CategoryQuery, CategoryQueryVariables>
    } & TChildProps;
export function withCategory<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CategoryQuery,
  CategoryQueryVariables,
  CategoryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CategoryQuery, CategoryQueryVariables, CategoryProps<TChildProps, TDataName>>(CategoryDocument, {
      alias: 'category',
      ...operationOptions
    });
};

/**
 * __useCategoryQuery__
 *
 * To run a query within a React component, call `useCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useCategoryQuery(baseOptions?: Apollo.QueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
      }
export function useCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
        }
export type CategoryQueryHookResult = ReturnType<typeof useCategoryQuery>;
export type CategoryLazyQueryHookResult = ReturnType<typeof useCategoryLazyQuery>;
export type CategoryQueryResult = Apollo.QueryResult<CategoryQuery, CategoryQueryVariables>;
export const BrandDocument = gql`
    query Brand($id: Int) {
  data: brand(id: $id) {
    id
    name
  }
}
    `;
export type BrandProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BrandQuery, BrandQueryVariables>
    } & TChildProps;
export function withBrand<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BrandQuery,
  BrandQueryVariables,
  BrandProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, BrandQuery, BrandQueryVariables, BrandProps<TChildProps, TDataName>>(BrandDocument, {
      alias: 'brand',
      ...operationOptions
    });
};

/**
 * __useBrandQuery__
 *
 * To run a query within a React component, call `useBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBrandQuery(baseOptions?: Apollo.QueryHookOptions<BrandQuery, BrandQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandQuery, BrandQueryVariables>(BrandDocument, options);
      }
export function useBrandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandQuery, BrandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandQuery, BrandQueryVariables>(BrandDocument, options);
        }
export type BrandQueryHookResult = ReturnType<typeof useBrandQuery>;
export type BrandLazyQueryHookResult = ReturnType<typeof useBrandLazyQuery>;
export type BrandQueryResult = Apollo.QueryResult<BrandQuery, BrandQueryVariables>;
export const SearchProductByBrandDocument = gql`
    query SearchProductByBrand($page: Int, $limit: Int, $orderBy: String, $brandId: Int) {
  data: searchProduct(page: $page, limit: $limit, orderBy: $orderBy, brandId: $brandId) {
    products {
      productId
      sku
      name
      price
      code
      status
      imageUrl
      quantity
      quantityAvailable
      hasVideo
      isContactProduct
      isInterested
      attributes {
        price
        listPrice
      }
    }
    page {
      total
      offset
      current
      last
      from
      to
    }
  }
}
    `;
export type SearchProductByBrandProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SearchProductByBrandQuery, SearchProductByBrandQueryVariables>
    } & TChildProps;
export function withSearchProductByBrand<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SearchProductByBrandQuery,
  SearchProductByBrandQueryVariables,
  SearchProductByBrandProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SearchProductByBrandQuery, SearchProductByBrandQueryVariables, SearchProductByBrandProps<TChildProps, TDataName>>(SearchProductByBrandDocument, {
      alias: 'searchProductByBrand',
      ...operationOptions
    });
};

/**
 * __useSearchProductByBrandQuery__
 *
 * To run a query within a React component, call `useSearchProductByBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductByBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductByBrandQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      brandId: // value for 'brandId'
 *   },
 * });
 */
export function useSearchProductByBrandQuery(baseOptions?: Apollo.QueryHookOptions<SearchProductByBrandQuery, SearchProductByBrandQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchProductByBrandQuery, SearchProductByBrandQueryVariables>(SearchProductByBrandDocument, options);
      }
export function useSearchProductByBrandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchProductByBrandQuery, SearchProductByBrandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchProductByBrandQuery, SearchProductByBrandQueryVariables>(SearchProductByBrandDocument, options);
        }
export type SearchProductByBrandQueryHookResult = ReturnType<typeof useSearchProductByBrandQuery>;
export type SearchProductByBrandLazyQueryHookResult = ReturnType<typeof useSearchProductByBrandLazyQuery>;
export type SearchProductByBrandQueryResult = Apollo.QueryResult<SearchProductByBrandQuery, SearchProductByBrandQueryVariables>;
export const CategoryTreeDocument = gql`
    query CategoryTree($id: Int) {
  data: categoryTree(id: $id) {
    id
    name
    parentId
    isActive
    urlKey
    path
  }
}
    `;
export type CategoryTreeProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CategoryTreeQuery, CategoryTreeQueryVariables>
    } & TChildProps;
export function withCategoryTree<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CategoryTreeQuery,
  CategoryTreeQueryVariables,
  CategoryTreeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CategoryTreeQuery, CategoryTreeQueryVariables, CategoryTreeProps<TChildProps, TDataName>>(CategoryTreeDocument, {
      alias: 'categoryTree',
      ...operationOptions
    });
};

/**
 * __useCategoryTreeQuery__
 *
 * To run a query within a React component, call `useCategoryTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryTreeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryTreeQuery(baseOptions?: Apollo.QueryHookOptions<CategoryTreeQuery, CategoryTreeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryTreeQuery, CategoryTreeQueryVariables>(CategoryTreeDocument, options);
      }
export function useCategoryTreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryTreeQuery, CategoryTreeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryTreeQuery, CategoryTreeQueryVariables>(CategoryTreeDocument, options);
        }
export type CategoryTreeQueryHookResult = ReturnType<typeof useCategoryTreeQuery>;
export type CategoryTreeLazyQueryHookResult = ReturnType<typeof useCategoryTreeLazyQuery>;
export type CategoryTreeQueryResult = Apollo.QueryResult<CategoryTreeQuery, CategoryTreeQueryVariables>;
export const CategoryDetailDocument = gql`
    query CategoryDetail($id: Int) {
  category(id: $id) {
    id
    name
    urlKey
    parentId
    metaTitle
    metaKeyword
    metaDescription
    flashSale
    imageIcon
    bannerUrl
    bannerThumbnailUrl
    description
    longDescription
  }
}
    `;
export type CategoryDetailProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CategoryDetailQuery, CategoryDetailQueryVariables>
    } & TChildProps;
export function withCategoryDetail<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CategoryDetailQuery,
  CategoryDetailQueryVariables,
  CategoryDetailProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CategoryDetailQuery, CategoryDetailQueryVariables, CategoryDetailProps<TChildProps, TDataName>>(CategoryDetailDocument, {
      alias: 'categoryDetail',
      ...operationOptions
    });
};

/**
 * __useCategoryDetailQuery__
 *
 * To run a query within a React component, call `useCategoryDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryDetailQuery(baseOptions?: Apollo.QueryHookOptions<CategoryDetailQuery, CategoryDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryDetailQuery, CategoryDetailQueryVariables>(CategoryDetailDocument, options);
      }
export function useCategoryDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryDetailQuery, CategoryDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryDetailQuery, CategoryDetailQueryVariables>(CategoryDetailDocument, options);
        }
export type CategoryDetailQueryHookResult = ReturnType<typeof useCategoryDetailQuery>;
export type CategoryDetailLazyQueryHookResult = ReturnType<typeof useCategoryDetailLazyQuery>;
export type CategoryDetailQueryResult = Apollo.QueryResult<CategoryDetailQuery, CategoryDetailQueryVariables>;
export const ProductWithReviewDocument = gql`
    query ProductWithReview($id: Int!, $limit: Int, $offset: Int) {
  data: productWithReview(id: $id, limit: $limit, offset: $offset) {
    product {
      id
      productId
      name
      productsetId
      sku
      code
      status
      inventoryType
      type
      quantity
      quantityAvailable
      minSaleQty
      maxSaleQty
      price
      description
      image
      imageUrl
      attributes {
        productCode
        productLength
        productWidth
        productHeight
        productWeight
        metaTitle
        metaKeywords
        metaDescription
        name
        description
        listPrice
        price
        image
        galleries
        brand {
          id
          value
          position
        }
        googleIndex
        canViewRating
        sanphamcobaogia
      }
      createdBy
      updatedBy
      shopeeId
      video
      hasVideo
      isContactProduct
      isInterested
      categories {
        id
        name
        parentId
        isActive
        path
        urlKey
      }
    }
    summary {
      total
      ratingCount
    }
    ratings {
      itemid
      ratingStar
      time
      comment
      images
      tags
      author
      authorLink
      authorPortrait
    }
  }
}
    `;
export type ProductWithReviewProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProductWithReviewQuery, ProductWithReviewQueryVariables>
    } & TChildProps;
export function withProductWithReview<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProductWithReviewQuery,
  ProductWithReviewQueryVariables,
  ProductWithReviewProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProductWithReviewQuery, ProductWithReviewQueryVariables, ProductWithReviewProps<TChildProps, TDataName>>(ProductWithReviewDocument, {
      alias: 'productWithReview',
      ...operationOptions
    });
};

/**
 * __useProductWithReviewQuery__
 *
 * To run a query within a React component, call `useProductWithReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductWithReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductWithReviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useProductWithReviewQuery(baseOptions: Apollo.QueryHookOptions<ProductWithReviewQuery, ProductWithReviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductWithReviewQuery, ProductWithReviewQueryVariables>(ProductWithReviewDocument, options);
      }
export function useProductWithReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductWithReviewQuery, ProductWithReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductWithReviewQuery, ProductWithReviewQueryVariables>(ProductWithReviewDocument, options);
        }
export type ProductWithReviewQueryHookResult = ReturnType<typeof useProductWithReviewQuery>;
export type ProductWithReviewLazyQueryHookResult = ReturnType<typeof useProductWithReviewLazyQuery>;
export type ProductWithReviewQueryResult = Apollo.QueryResult<ProductWithReviewQuery, ProductWithReviewQueryVariables>;