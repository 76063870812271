import React, {
  FunctionComponent,
  Fragment,
  useState,
  useCallback,
  memo,
} from 'react';
import { Form, Button, Spinner, Alert } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useTimeout from '@restart/hooks/useTimeout';
import { MODAL_FORM } from '../intl';
import _get from 'lodash/get';
import { DELAY } from '@helpers/const';

import { useResetPassword } from '../hooks/use-reset-password';

const Message = memo(() => (
  <div>
    <div className="reset-empty">
      <div className="d-flex justify-content-center">
        <img src="/assets/skeleton-01.svg" className="img-fluid" alt="Info" />
      </div>
      <div className="text-center">
        {/* <h3 className="mt-4 mb-2">{MODAL_FORM.RESET_PASSWORD_MESSAGE}</h3> */}
        <p className="mt-4 mb-2">{MODAL_FORM.RESET_PASSWORD_SUCCESS_MESSAGE}</p>
      </div>
    </div>
  </div>
));

interface IValues {
  email: string;
}

const INITIAL_VALUES: IValues = {
  email: '',
};

const SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email(MODAL_FORM.EMAIL_INVALID)
    .required(MODAL_FORM.REQUIRED),
});

interface ForgetPasswordProps {
  onClose: () => void;
  onGotoSignUp: () => void;
  onGotoLogin: () => void;
}

export const ForgetPassword: FunctionComponent<ForgetPasswordProps> = ({
  onClose,
  onGotoSignUp,
  onGotoLogin,
}) => {
  const [resetPassword] = useResetPassword();

  const [success, setSuccess] = useState(false);
  const [isFetchError, setIsFetchError] = useState(false);
  const [fetchMessage, setFetchMessage] = useState(null);

  const timeout = useTimeout();
  const clearFetchError = useCallback(() => {
    setFetchMessage(null);
    setIsFetchError(false);
  }, [isFetchError]);

  const setFetchError = useCallback((message) => {
    setFetchMessage(message);
    setIsFetchError(true);
  }, []);

  const onSubmit = async (values: IValues, { setSubmitting }) => {
    try {
      await resetPassword({
        variables: values,
        update: async () => {
          setSuccess(true);
          setSubmitting(false);

          // await onClose();
        },
      });
    } catch (error) {
      setFetchError(_get(error, 'graphQLErrors[0].message'));
      timeout.set(clearFetchError, DELAY);
    }
  };

  if (success) {
    return <Message />;
  }

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={SCHEMA}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        isValid,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit} className="form form--sign">
          <p className="mb-4">
            Vui lòng cung cấp email đăng nhập để lấy lại mật khẩu.
          </p>
          {isFetchError && (
            <Alert variant="danger" className="mb-4">
              {fetchMessage}
            </Alert>
          )}
          <Form.Group>
            <Form.Control
              required
              type="email"
              placeholder={MODAL_FORM.EMAIL}
              name="email"
              value={values.email}
              onChange={handleChange}
              isInvalid={!!errors.email}
              autoComplete="off"
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="d-flex">
            <Button
              type="submit"
              variant="primary"
              className="flex-fill d-flex justify-content-center align-items-center"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Fragment>
                  <Spinner
                    as="span"
                    animation="grow"
                    role="status"
                    aria-hidden="true"
                  />
                  {MODAL_FORM.SUBMITTING}
                </Fragment>
              ) : (
                <Fragment>Gửi</Fragment>
              )}
            </Button>
          </div>
          <div className="reset-password">
            {MODAL_FORM.HAS_ACCOUNT_ALREADY}{' '}
            <a className="text-primary" onClick={onGotoLogin}>
              {MODAL_FORM.LOGIN}
            </a>
          </div>
          <div className="switch-mode">
            {MODAL_FORM.HAS_ACCOUNT_YET}{' '}
            <a className="text-primary" onClick={onGotoSignUp}>
              {MODAL_FORM.SIGN_UP}
            </a>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default memo(ForgetPassword);
