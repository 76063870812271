import React, { FC, memo } from 'react';
import Link from 'next/link';
import cn from 'classnames';
import get from 'lodash/get';

import { Container, Badge } from 'react-bootstrap';
import IndicatorCart from './component/indicator-cart';
import IndicatorMobile from './component/indicator-mobile';
import SearchForm from './component/search';
import { MegaMenuIcon, MegaMenuPanel } from './component/mega-menu';

import useMegaMenuState from './hooks/use-mega-menu';
import { useConfig } from '@server/lib/config';
import useLayoutContext from '@views/layouts/hooks/use-context';
import LogoIcon from '@views/static/logo.svg';

import useCategory from '@hooks/use-category';
import { absoluteUrl } from '@lib/helpers';

const HeaderMain: FC<any> = memo(
  (props): JSX.Element => {
    const {
      authenticated,
      checkout: { data },
      activeCartToast,
      setActiveCartToast,
    } = useLayoutContext();

    const cart = get(data, 'checkout.cart', []);
    const quantity = cart?.reduce((node: number, { quantity }) => {
      return node + quantity || 0;
    }, 0);

    const { IS_BETA = false } = useConfig();

    const { loading, error, categories } = useCategory();
    const [isActive, setIsActive] = useMegaMenuState();

    return (
      // <!-- Header Search -->
      <section className="navbar navbar--search">
        <Container style={{ position: 'relative' }}>
          <Link href="/">
            <a
              aria-label="Văn Phòng Phẩm Sơn Thủy"
              title="Văn Phòng Phẩm Sơn Thủy"
              className="navbar-brand navbar--brand"
            >
              {/* <LogoIcon /> */}
              <img
                src={absoluteUrl('/assets/logo/logo ST-02-small.png')}
                className="img-fluid loaded"
              />
              {IS_BETA && <Badge>BETA</Badge>}
            </a>
          </Link>

          <IndicatorMobile authenticated={authenticated} quantity={quantity} />

          <SearchForm menuIcon={<MegaMenuIcon setIsActive={setIsActive} />}>
            <IndicatorCart
              hideOnMobile
              quantity={quantity}
              showToast={activeCartToast}
              onCloseToast={() => setActiveCartToast(false)}
            />
          </SearchForm>
          <MegaMenuPanel
            // @ts-ignore
            categories={categories}
            loading={loading}
            error={error}
            setIsActive={setIsActive}
            className={cn({ 'd-none': !isActive })}
          />
        </Container>
      </section>
    );
  }
);

export default HeaderMain;
