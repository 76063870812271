import { ENDPOINT, PRODUCTION } from '@server/lib/config';
export const BETA_MAPS = {
  '2': {
    id: '2',
    name: 'Root Wholesale',
    parentId: 0,
    path: '2',
    urlKey: 'root_wholesale',
    flashSale: null,
    imageIcon: null,
  },
  '797': {
    id: '797',
    name: 'Bách hoá',
    parentId: 2,
    path: '2/797',
    urlKey: 'bach-hoa',
    flashSale: null,
    imageIcon: null,
  },
  '798': {
    id: '798',
    name: 'Giấy in ấn',
    parentId: 2,
    path: '2/798',
    urlKey: 'giay-in-an',
    flashSale: null,
    imageIcon: null,
  },
  '800': {
    id: '800',
    name: 'Giấy in ấn A4',
    parentId: 2,
    path: '2/800',
    urlKey: 'giay-in-an-a4',
    flashSale: null,
    imageIcon: null,
  },
  '801': {
    id: '801',
    name: 'Bìa kiếng',
    parentId: 2,
    path: '2/801',
    urlKey: 'bia-kieng',
    flashSale: null,
    imageIcon: null,
  },
  '802': {
    id: '802',
    name: 'Danh mục sỉ Vi-1',
    parentId: 2,
    path: '2/802',
    urlKey: 'danh-muc-si-vi-1',
    flashSale: null,
    imageIcon:
      'https://cdnbeta.fast.vn/tmp/20220616190440-z3312120123344_28c331c3f4fe129b85c738a1b0caa388.jpg',
  },
  '803': {
    id: '803',
    name: 'Danh mục con Vi',
    parentId: 2,
    path: '2/803',
    urlKey: 'danh-muc-con-vi',
    flashSale: null,
    imageIcon: null,
  },
};

export const PROD_MAPS = {
  '2': {
    id: '2',
    name: 'Root Wholesale',
    parentId: 0,
    path: '2',
    urlKey: 'root_wholesale',
    flashSale: null,
    imageIcon: null,
  },
  '1479': {
    id: '1479',
    name: 'Giấy in Ấn - Photo',
    parentId: 2,
    path: '2/1479',
    urlKey: 'giay-in-an---photo',
    flashSale: null,
    imageIcon: null,
  },
  '1480': {
    id: '1480',
    name: 'Bìa - Kệ - Rổ',
    parentId: 2,
    path: '2/1480',
    urlKey: 'bia---ke---ro',
    flashSale: null,
    imageIcon: null,
  },
  '1481': {
    id: '1481',
    name: 'Sổ - Tập - Bao Thư ',
    parentId: 2,
    path: '2/1481',
    urlKey: 'so---tap---bao-thu-',
    flashSale: null,
    imageIcon: null,
  },
  '1482': {
    id: '1482',
    name: 'Đồ Dùng Học Sinh',
    parentId: 2,
    path: '2/1482',
    urlKey: 'do-dung-hoc-sinh',
    flashSale: null,
    imageIcon: null,
  },
  '1483': {
    id: '1483',
    name: 'Bút - Mực Chất Lượng Cao ',
    parentId: 2,
    path: '2/1483',
    urlKey: 'but---muc-chat-luong-cao-',
    flashSale: null,
    imageIcon: null,
  },
  '1484': {
    id: '1484',
    name: 'Dụng Cụ Văn Phòng Chất Lượng',
    parentId: 2,
    path: '2/1484',
    urlKey: 'dung-cu-van-phong-chat-luong',
    flashSale: null,
    imageIcon: null,
  },
  '1485': {
    id: '1485',
    name: 'Băng Keo - Dao - Kéo',
    parentId: 2,
    path: '2/1485',
    urlKey: 'bang-keo---dao---keo',
    flashSale: null,
    imageIcon: null,
  },
  '1486': {
    id: '1486',
    name: 'Máy Tính Casio',
    parentId: 2,
    path: '2/1486',
    urlKey: 'may-tinh-casio',
    flashSale: null,
    imageIcon: null,
  },
  '1487': {
    id: '1487',
    name: 'Bách Hóa Online ',
    parentId: 2,
    path: '2/1487',
    urlKey: 'bach-hoa-online-',
    flashSale: null,
    imageIcon: null,
  },
  '1488': {
    id: '1488',
    name: 'Bảng Văn Phòng',
    parentId: 2,
    path: '2/1488',
    urlKey: 'bang-van-phong',
    flashSale: null,
    imageIcon: null,
  },
  '1489': {
    id: '1489',
    name: 'Dịch Vụ Khắc Dấu Uy Tín',
    parentId: 2,
    path: '2/1489',
    urlKey: 'dich-vu-khac-dau-uy-tin',
    flashSale: null,
    imageIcon: null,
  },
  '1490': {
    id: '1490',
    name: 'Giấy In Ảnh - In Màu Chất Lượng Cao',
    parentId: 2,
    path: '2/1490',
    urlKey: 'giay-in-anh---in-mau-chat-luong-cao',
    flashSale: null,
    imageIcon: null,
  },
  '1491': {
    id: '1491',
    name: 'Chăm Sóc Nhà Cửa ',
    parentId: 2,
    path: '2/1491',
    urlKey: 'cham-soc-nha-cua-',
    flashSale: null,
    imageIcon: null,
  },
  '1492': {
    id: '1492',
    name: 'Điện Gia Dụng',
    parentId: 2,
    path: '2/1492',
    urlKey: 'dien-gia-dung',
    flashSale: null,
    imageIcon: null,
  },
};

export default ENDPOINT == PRODUCTION ? PROD_MAPS : BETA_MAPS;
