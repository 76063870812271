import styled from 'styled-components';
import { media } from '@views/globalStyles';

export const searchIcon = '/assets/icon/icon-search.png';
export const SearchButton = styled.button`
  background: var(--primary) url(${searchIcon}) center center no-repeat;
  background-size: 40%;
  border: 2px solid #fff;
  border-radius: 4px;
`;

export const Wrapper = styled.div`
  position: relative;
  border-radius: ${(props) => (props.hasFocus ? '4px 4px 0 0' : '4px')};
`;

export const Input = styled.input``;

export const SearchAutocomplete = styled.div`
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  width: 100%;
  list-style: none;
  background-color: rgb(255, 255, 255);
  border-radius: 0px 0px 3px 3px;
  border-top: ${(props) =>
    props.empty ? 'none' : '1px solid rgb(225, 225, 225)'};
  // box-shadow: rgb(0 0 0 / 28%) 0px 6px;
  z-index: 9999;
  display: grid;
  grid-template-columns: 1fr;
  ${(props) => media.smallScreen`
    grid-template-columns: 1fr;
  `}
  max-height: 500px;
  overflow: auto;
`;

export const SearchList = styled.div`
  background-color: rgb(255, 255, 255);

  .search-item {
    color: rgb(36, 36, 36);
    min-height: 36px;
    font-size: 12px;
    line-height: 1.67;
    text-decoration: none;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 0px 16px;
    &:first-child {
      border-top: 0px;
    }
    & > .item-icon {
      width: 24px;
      height: 24px;
    }
    & > .keyword {
      flex: 1 1 0%;
      padding: 0px 0px 0px 8px;
    }

    & > .info {
      display: flex;
      flex-direction: column;
      flex: 1 1 0%;
      margin-left: 8px;
      margin-bottom: 4px;

      .title-prefix {
        font-weight: 400;
        color: rgb(120, 120, 120);
        font-size: 11px;
        line-height: 16px;
      }

      .title {
        color: var(--primary);
        line-height: 1.5;
      }
    }
  }
`;

export const CategoryWrapper = styled.div`
  border-left: 1px solid #e7e6e6;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 24px;
  color: #000;
  padding: 4px 16px;
  margin: 12px 0;

  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-right: 12px;
  }
`;
export const TopKeyword = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  padding: 0 16px 10px;
  grid-template-columns: repeat(4, 4fr);
  ${(props) => media.smallScreen`
    grid-template-columns: repeat(2, 1fr);
  `}
  .search-item {
    background: rgb(250, 250, 250);
    border-radius: 4px;
  }
`;

export const CategoryList = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  padding: 0 16px 16px 16px;
  grid-template-columns: repeat(4, 1fr);
  ${(props) => media.smallScreen`
    grid-template-columns: repeat(2, 1fr);
  `}
`;
export const CategoryItem = styled.div`
  background-color: rgb(255, 255, 255);
  .search-item {
    color: rgb(36, 36, 36);
    min-height: 36px;
    font-size: 12px;
    line-height: 1.67;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 6px;
    // background: rgb(250, 250, 250);
    border-radius: 4px;
    font-weight: 500;
    height: 100%;
    overflow: hidden;

    .item-icon {
      padding: 0px 40px;
      max-width: 100%;
      height: 40px;
      object-fit: contain;
      margin-bottom: 6px;
    }
    & > .info {
      display: flex;
      flex-direction: column;
      flex: 1 1 0%;
      margin-left: 8px;
      margin-bottom: 4px;

      .title-prefix {
        font-weight: 400;
        color: rgb(120, 120, 120);
        font-size: 11px;
        line-height: 16px;
      }

      .title {
        color: var(--primary);
        line-height: 1.5;
      }
    }
  }
`;

export const QuickLink = styled.div`
  position: absolute;
  bottom: -26px;
  left: 0;
  right: 0;
  height: 24px;
  overflow: hidden;
  ${(props) => media.smallScreen`
    display: none;
  `}

  & > a {
    color: #fff;
    font-weight: 500;
    margin-right: 12px;
    .keyword {
      font-size: 0.875em;
    }
  }
`;
