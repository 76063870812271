import { ENDPOINT, PRODUCTION } from '@server/lib/config';

export const BETA_DATA = {
  data: {
    categories: [
      {
        id: '2',
        name: 'Root Wholesale',
        parentId: 0,
        path: '2',
        urlKey: 'root_wholesale',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '797',
        name: 'Bách hoá',
        parentId: 2,
        path: '2/797',
        urlKey: 'bach-hoa',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '798',
        name: 'Giấy in ấn',
        parentId: 2,
        path: '2/798',
        urlKey: 'giay-in-an',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '800',
        name: 'Giấy in ấn A4',
        parentId: 2,
        path: '2/800',
        urlKey: 'giay-in-an-a4',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '801',
        name: 'Bìa kiếng',
        parentId: 2,
        path: '2/801',
        urlKey: 'bia-kieng',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '802',
        name: 'Danh mục sỉ Vi-1',
        parentId: 2,
        path: '2/802',
        urlKey: 'danh-muc-si-vi-1',
        flashSale: null,
        imageIcon:
          'https://cdnbeta.fast.vn/tmp/20220616190440-z3312120123344_28c331c3f4fe129b85c738a1b0caa388.jpg',
      },
      {
        id: '803',
        name: 'Danh mục con Vi',
        parentId: 2,
        path: '2/803',
        urlKey: 'danh-muc-con-vi',
        flashSale: null,
        imageIcon: null,
      },
    ],
  },
};

export const PROD_DATA = {
  data: {
    categories: [
      {
        id: '2',
        name: 'Root Wholesale',
        parentId: 0,
        path: '2',
        urlKey: 'root_wholesale',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1479',
        name: 'Giấy in Ấn - Photo',
        parentId: 2,
        path: '2/1479',
        urlKey: 'giay-in-an---photo',
        flashSale: null,
        imageIcon:
          'https://cdn.fast.vn/tmp/20220624222801-giấy-in-ấn-photo.png',
      },
      {
        id: '1480',
        name: 'Bìa - Kệ - Rổ',
        parentId: 2,
        path: '2/1480',
        urlKey: 'bia---ke---ro',
        flashSale: null,
        imageIcon: 'https://cdn.fast.vn/tmp/20220624223218-rổ-bìa-kệ.png',
      },
      {
        id: '1481',
        name: 'Sổ - Tập - Bao Thư',
        parentId: 2,
        path: '2/1481',
        urlKey: 'so---tap---bao-thu-',
        flashSale: null,
        imageIcon: 'https://cdn.fast.vn/tmp/20220624223247-sổ-tập-bao-thư.png',
      },
      {
        id: '1482',
        name: 'Đồ Dùng Học Sinh',
        parentId: 2,
        path: '2/1482',
        urlKey: 'do-dung-hoc-sinh',
        flashSale: null,
        imageIcon:
          'https://cdn.fast.vn/tmp/20220624223308-dồ-dùng-học-sinh.png',
      },
      {
        id: '1483',
        name: 'Bút - Mực Chất Lượng Cao',
        parentId: 2,
        path: '2/1483',
        urlKey: 'but---muc-chat-luong-cao-',
        flashSale: null,
        imageIcon:
          'https://cdn.fast.vn/tmp/20220624223324-bút-mực-chất-lượng-cao.png',
      },
      {
        id: '1484',
        name: 'Dụng Cụ Văn Phòng Chất Lượng',
        parentId: 2,
        path: '2/1484',
        urlKey: 'dung-cu-van-phong-chat-luong',
        flashSale: null,
        imageIcon:
          'https://cdn.fast.vn/tmp/20220624223357-dồ-dùng-văn-phòng-chất-lượng.png',
      },
      {
        id: '1485',
        name: 'Băng Keo - Dao - Kéo',
        parentId: 2,
        path: '2/1485',
        urlKey: 'bang-keo---dao---keo',
        flashSale: null,
        imageIcon:
          'https://cdn.fast.vn/tmp/20220624223415-băng-keo-dao-kéo.png',
      },
      {
        id: '1486',
        name: 'Máy Tính Casio',
        parentId: 2,
        path: '2/1486',
        urlKey: 'may-tinh-casio',
        flashSale: null,
        imageIcon: 'https://cdn.fast.vn/tmp/20220624223430-máy-tính-casio.png',
      },
      {
        id: '1487',
        name: 'Bách Hóa Online',
        parentId: 2,
        path: '2/1487',
        urlKey: 'bach-hoa-online-',
        flashSale: null,
        imageIcon: 'https://cdn.fast.vn/tmp/20220624223443-bách-hóa-online.png',
      },
      {
        id: '1488',
        name: 'Bảng Văn Phòng',
        parentId: 2,
        path: '2/1488',
        urlKey: 'bang-van-phong',
        flashSale: null,
        imageIcon: 'https://cdn.fast.vn/tmp/20220624223456-bảng-văn-phòng.png',
      },
      {
        id: '1489',
        name: 'Dịch Vụ Khắc Dấu Uy Tín',
        parentId: 2,
        path: '2/1489',
        urlKey: 'dich-vu-khac-dau-uy-tin',
        flashSale: null,
        imageIcon:
          'https://cdn.fast.vn/tmp/20220624223515-dịch-vụ-khắc-dấu-uy-tín.png',
      },
      {
        id: '1490',
        name: 'Giấy In Ảnh - In Màu Chất Lượng Cao',
        parentId: 2,
        path: '2/1490',
        urlKey: 'giay-in-anh---in-mau-chat-luong-cao',
        flashSale: null,
        imageIcon:
          'https://cdn.fast.vn/tmp/20220624223539-giấy-in-ảnh-in-màu-chất-lượng-cao.png',
      },
      {
        id: '1491',
        name: 'Chăm Sóc Nhà Cửa',
        parentId: 2,
        path: '2/1491',
        urlKey: 'cham-soc-nha-cua-',
        flashSale: null,
        imageIcon:
          'https://cdn.fast.vn/tmp/20220624223555-chăm-sóc-nhà-cửa.png',
      },
      {
        id: '1492',
        name: 'Điện Gia Dụng',
        parentId: 2,
        path: '2/1492',
        urlKey: 'dien-gia-dung',
        flashSale: null,
        imageIcon: 'https://cdn.fast.vn/tmp/20220624223607-diện-gia-dụng.png',
      },
      {
        id: '1493',
        name: 'Giấy in văn phòng - Giấy photo chất lượng',
        parentId: 1479,
        path: '2/1479/1493',
        urlKey: 'giay-in-van-phong---giay-photo-chat-luong',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1494',
        name: 'Giấy in liên tục -In bill -Fax nhiệt',
        parentId: 1479,
        path: '2/1479/1494',
        urlKey: 'giay-in-lien-tuc--in-bill--fax-nhiet',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1495',
        name: 'Giấy note - Giấy phân trang',
        parentId: 1479,
        path: '2/1479/1495',
        urlKey: 'giay-note---giay-phan-trang',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1496',
        name: 'Decal đế xanh - Decal đế vàng -Tomy',
        parentId: 1479,
        path: '2/1479/1496',
        urlKey: 'decal-de-xanh---decal-de-vang--tomy',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1497',
        name: 'Giấy than - Giấy kẽ ngang - Giấy Roky',
        parentId: 1479,
        path: '2/1479/1497',
        urlKey: 'giay-than---giay-ke-ngang---giay-roky',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1498',
        name: 'Giấy FO màu',
        parentId: 1479,
        path: '2/1479/1498',
        urlKey: 'giay-fo-mau',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1499',
        name: 'Bìa lá -trình ký -Cardcase',
        parentId: 1480,
        path: '2/1480/1499',
        urlKey: 'bia-la--trinh-ky--cardcase',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1500',
        name: 'Bìa lỗ -Phân trang -Bìa lò xo',
        parentId: 1480,
        path: '2/1480/1500',
        urlKey: 'bia-lo--phan-trang--bia-lo-xo',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1501',
        name: 'Rổ xéo -Kệ nhựa -Kệ mica',
        parentId: 1480,
        path: '2/1480/1501',
        urlKey: 'ro-xeo--ke-nhua--ke-mica',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1502',
        name: 'Bìa nút -Cặp 12 ngăn -Bìa kẹp',
        parentId: 1480,
        path: '2/1480/1502',
        urlKey: 'bia-nut--cap-12-ngan--bia-kep',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1503',
        name: 'Bìa treo -Bìa cây -Bìa accor',
        parentId: 1480,
        path: '2/1480/1503',
        urlKey: 'bia-treo--bia-cay--bia-accor',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1504',
        name: 'Bìa dây -Bìa hộp',
        parentId: 1480,
        path: '2/1480/1504',
        urlKey: 'bia-day--bia-hop',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1505',
        name: 'Bìa nhiều lá nhựa - da',
        parentId: 1480,
        path: '2/1480/1505',
        urlKey: 'bia-nhieu-la-nhua---da',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1506',
        name: 'Bìa thái',
        parentId: 1480,
        path: '2/1480/1506',
        urlKey: 'bia-thai',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1507',
        name: 'Bìa kiếng',
        parentId: 1480,
        path: '2/1480/1507',
        urlKey: 'bia-kieng',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1508',
        name: 'Bìa Còng',
        parentId: 1480,
        path: '2/1480/1508',
        urlKey: 'bia-cong',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1509',
        name: 'Sổ da đen - Sổ lò xo - Sổ caro ',
        parentId: 1481,
        path: '2/1481/1509',
        urlKey: 'so-da-den---so-lo-xo---so-caro-',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1510',
        name: 'Tập vở - Bao thư',
        parentId: 1481,
        path: '2/1481/1510',
        urlKey: 'tap-vo---bao-thu',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1511',
        name: 'Sổ Namecard - Hộp đựng Namecard',
        parentId: 1481,
        path: '2/1481/1511',
        urlKey: 'so-namecard---hop-dung-namecard',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1512',
        name: 'Phiếu Thu Chi - Phiếu Nhập Xuất Kho',
        parentId: 1481,
        path: '2/1481/1512',
        urlKey: 'phieu-thu-chi---phieu-nhap-xuat-kho',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1513',
        name: 'Bút bi - Bút nước - Bút ký',
        parentId: 1483,
        path: '2/1483/1513',
        urlKey: 'but-bi---but-nuoc---but-ky',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1515',
        name: 'Bút dạ quang đầy đủ màu sắc, chất lượng',
        parentId: 1483,
        path: '2/1483/1515',
        urlKey: 'but-da-quang-day-du-mau-sac--chat-luong',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1516',
        name: 'Bút xóa - Băng xóa - Ruột xóa',
        parentId: 1483,
        path: '2/1483/1516',
        urlKey: 'but-xoa---bang-xoa---ruot-xoa',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1517',
        name: 'Bút chì -Ruột chì -Tẩy -Chuốt- Giá tốt',
        parentId: 1483,
        path: '2/1483/1517',
        urlKey: 'but-chi--ruot-chi--tay--chuot--gia-tot',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1518',
        name: 'Mực dấu -Lông bảng -Lông dầu',
        parentId: 1483,
        path: '2/1483/1518',
        urlKey: 'muc-dau--long-bang--long-dau',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1519',
        name: 'Bút bi-bút nước Thiên Long',
        parentId: 1483,
        path: '2/1483/1519',
        urlKey: 'but-bi-but-nuoc-thien-long',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1520',
        name: 'Bút lông bảng ',
        parentId: 1483,
        path: '2/1483/1520',
        urlKey: 'but-long-bang-',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1521',
        name: 'Bút lông dầu đa dạng, phong phú ',
        parentId: 1483,
        path: '2/1483/1521',
        urlKey: 'but-long-dau-da-dang--phong-phu-',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1522',
        name: 'Hộp cắm bút',
        parentId: 1483,
        path: '2/1483/1522',
        urlKey: 'hop-cam-but',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1523',
        name: 'Bấm kim -Kim bấm -Kẹp giấy',
        parentId: 1484,
        path: '2/1484/1523',
        urlKey: 'bam-kim--kim-bam--kep-giay',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1524',
        name: 'Kẹp bướm -Kẹp acco -Gỡ ghim',
        parentId: 1484,
        path: '2/1484/1524',
        urlKey: 'kep-buom--kep-acco--go-ghim',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1525',
        name: 'Máy bấm kim -Bấm lỗ các loại',
        parentId: 1484,
        path: '2/1484/1525',
        urlKey: 'may-bam-kim--bam-lo-cac-loai',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1526',
        name: 'Bảng tên - dây đeo ',
        parentId: 1484,
        path: '2/1484/1526',
        urlKey: 'bang-ten---day-deo-',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1527',
        name: 'Tủ hồ sơ - kính lúp',
        parentId: 1484,
        path: '2/1484/1527',
        urlKey: 'tu-ho-so---kinh-lup',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1528',
        name: 'Ép Plastic A3,A4,A5,CMND,bằng lái',
        parentId: 1484,
        path: '2/1484/1528',
        urlKey: 'ep-plastic-a3-a4-a5-cmnd-bang-lai',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1529',
        name: 'Máy bấm kim đại -kim bấm',
        parentId: 1484,
        path: '2/1484/1529',
        urlKey: 'may-bam-kim-dai--kim-bam',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1530',
        name: 'Máy bấm gỗ -kim bấm gỗ',
        parentId: 1484,
        path: '2/1484/1530',
        urlKey: 'may-bam-go--kim-bam-go',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1531',
        name: 'Bấm kim trung(03) -kim bấm',
        parentId: 1484,
        path: '2/1484/1531',
        urlKey: 'bam-kim-trung-03---kim-bam',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1532',
        name: 'Băng keo trong/đục -Simili ',
        parentId: 1485,
        path: '2/1485/1532',
        urlKey: 'bang-keo-trong-duc--simili-',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1533',
        name: 'Cắt băng keo',
        parentId: 1485,
        path: '2/1485/1533',
        urlKey: 'cat-bang-keo',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1534',
        name: 'Dao rọc giấy - lưỡi dao',
        parentId: 1485,
        path: '2/1485/1534',
        urlKey: 'dao-roc-giay---luoi-dao',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1535',
        name: 'Súng bắn keo -Bắn giá -Keo silicon',
        parentId: 1485,
        path: '2/1485/1535',
        urlKey: 'sung-ban-keo--ban-gia--keo-silicon',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1536',
        name: 'Băng keo 2 mặt -Giấy -Xốp',
        parentId: 1485,
        path: '2/1485/1536',
        urlKey: 'bang-keo-2-mat--giay--xop',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1537',
        name: 'Hồ nước -Hồ khô',
        parentId: 1485,
        path: '2/1485/1537',
        urlKey: 'ho-nuoc--ho-kho',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1538',
        name: 'Kéo các loại',
        parentId: 1485,
        path: '2/1485/1538',
        urlKey: 'keo-cac-loai',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1539',
        name: 'Bàn cắt giấy A4 -A3',
        parentId: 1485,
        path: '2/1485/1539',
        urlKey: 'ban-cat-giay-a4--a3',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1540',
        name: 'Thước các loại',
        parentId: 1485,
        path: '2/1485/1540',
        urlKey: 'thuoc-cac-loai',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1541',
        name: 'Tạp hóa văn phòng',
        parentId: 1487,
        path: '2/1487/1541',
        urlKey: 'tap-hoa-van-phong',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1542',
        name: 'Nước uống các loại',
        parentId: 1487,
        path: '2/1487/1542',
        urlKey: 'nuoc-uong-cac-loai',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1543',
        name: 'Cà Phê',
        parentId: 1487,
        path: '2/1487/1543',
        urlKey: 'ca-phe',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1544',
        name: 'Trà',
        parentId: 1487,
        path: '2/1487/1544',
        urlKey: 'tra',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1545',
        name: 'Bánh kẹo',
        parentId: 1487,
        path: '2/1487/1545',
        urlKey: 'banh-keo',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1546',
        name: 'Mì -Miến -Cháo -Phở',
        parentId: 1487,
        path: '2/1487/1546',
        urlKey: 'mi--mien--chao--pho',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1547',
        name: 'Đồ uống các loại',
        parentId: 1487,
        path: '2/1487/1547',
        urlKey: 'do-uong-cac-loai',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1548',
        name: 'Vệ sinh nhà cửa',
        parentId: 1487,
        path: '2/1487/1548',
        urlKey: 've-sinh-nhacua',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1549',
        name: 'Bảng viết bút lông cao cấp',
        parentId: 1488,
        path: '2/1488/1549',
        urlKey: 'bang-viet-but-long-cao-cap',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1550',
        name: 'Bảng ghim - Bảng lịch công tác - Bảng viết phấn',
        parentId: 1488,
        path: '2/1488/1550',
        urlKey: 'bang-ghim---bang-lich-cong-tac---bang-viet-phan',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1551',
        name: 'Bảng di động',
        parentId: 1488,
        path: '2/1488/1551',
        urlKey: 'bang-di-dong',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1552',
        name: 'Bảng Flipchart ',
        parentId: 1488,
        path: '2/1488/1552',
        urlKey: 'bang-flipchart-',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1553',
        name: 'Mút lau bảng,Nam châm,Phấn ',
        parentId: 1488,
        path: '2/1488/1553',
        urlKey: 'mut-lau-bang-nam-cham-phan-',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1554',
        name: 'Chăm sóc cá nhân',
        parentId: 1487,
        path: '2/1487/1554',
        urlKey: 'cham-soc-ca-nhan',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1555',
        name: 'Con dấu tên - Chức danh ',
        parentId: 1489,
        path: '2/1489/1555',
        urlKey: 'con-dau-ten---chuc-danh-',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1556',
        name: 'Máy đóng số xoay -Số nhảy',
        parentId: 1489,
        path: '2/1489/1556',
        urlKey: 'may-dong-so-xoay--so-nhay',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1557',
        name: 'Tampon dấu - Caosu mặt dấu chất lượng tốt ',
        parentId: 1489,
        path: '2/1489/1557',
        urlKey: 'tampon-dau---caosu-mat-dau-chat-luong-tot-',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1558',
        name: 'Bảng tên CTY-Số nhà-Phòng ban',
        parentId: 1489,
        path: '2/1489/1558',
        urlKey: 'bang-ten-cty-so-nha-phong-ban',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1559',
        name: 'Dấu chữ ký -Bút ký có dấu',
        parentId: 1489,
        path: '2/1489/1559',
        urlKey: 'dau-chu-ky--but-ky-co-dau',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1560',
        name: 'Dấu ngày..tháng..năm - tự động',
        parentId: 1489,
        path: '2/1489/1560',
        urlKey: 'dau-ngay--thang--nam---tu-dong',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1561',
        name: 'Dấu tròn',
        parentId: 1489,
        path: '2/1489/1561',
        urlKey: 'dau-tron',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1562',
        name: 'Dấu vuông thông dụng',
        parentId: 1489,
        path: '2/1489/1562',
        urlKey: 'dau-vuong-thong-dung',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1563',
        name: 'Tampon- Con dấu- Mực dấu Shiny',
        parentId: 1489,
        path: '2/1489/1563',
        urlKey: 'tampon--con-dau--muc-dau-shiny',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1564',
        name: 'Giấy ảnh ',
        parentId: 1490,
        path: '2/1490/1564',
        urlKey: 'giay-anh-',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1565',
        name: 'Giấy in màu ',
        parentId: 1490,
        path: '2/1490/1565',
        urlKey: 'giay-in-mau-',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1566',
        name: 'Chất tẩy rửa -Vật phẩm vệ sinh',
        parentId: 1491,
        path: '2/1491/1566',
        urlKey: 'chat-tay-rua--vat-pham-ve-sinh',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1567',
        name: 'Thùng rác -Dụng cụ vệ sinh',
        parentId: 1491,
        path: '2/1491/1567',
        urlKey: 'thung-rac--dung-cu-ve-sinh',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1568',
        name: 'Ổ cắm điện -Phích cắm',
        parentId: 1492,
        path: '2/1492/1568',
        urlKey: 'o-cam-dien--phich-cam',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1569',
        name: 'Bóng đèn -Đèn ngủ',
        parentId: 1492,
        path: '2/1492/1569',
        urlKey: 'bong-den--den-ngu',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1570',
        name: 'Đèn để bàn -Đèn bắt muỗi',
        parentId: 1492,
        path: '2/1492/1570',
        urlKey: 'den-de-ban--den-bat-muoi',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1571',
        name: 'Đèn pin - Đèn đội đầu',
        parentId: 1492,
        path: '2/1492/1571',
        urlKey: 'den-pin---den-doi-dau',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1572',
        name: 'Quạt làm mát',
        parentId: 1492,
        path: '2/1492/1572',
        urlKey: 'quat-lam-mat',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1573',
        name: 'Thiết bị điện ',
        parentId: 1492,
        path: '2/1492/1573',
        urlKey: 'thiet-bi-dien-',
        flashSale: null,
        imageIcon: null,
      },
      {
        id: '1574',
        name: 'Giấy in Bill - Decal Nhiệt - Máy in đơn hàng Shopee Lazada',
        parentId: 2,
        path: '2/1574',
        urlKey: 'giay-in-bill---decal-nhiet---may-in-don-hang-shopee-lazada',
        flashSale: null,
        imageIcon:
          'https://cdn.fast.vn/tmp/20220624223636-giấy-in-ấn-photo.png',
      },
    ],
  },
};

export default ENDPOINT == PRODUCTION ? PROD_DATA : BETA_DATA;
