import React, { useRef, MutableRefObject } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import cn from 'classnames';
import { ICategory } from '@server/data/models/category';
import LazyImage, { LazyLoadImage } from '@views/components/lazy-image';
import _get from 'lodash/get';
import _truncate from 'lodash/truncate';
import { categoryHref, INTERNAL_URI } from '@helpers/route';
import {
  EVENT_CLASS_NAME,
  EVENT_ACTION_NAME,
  EVENT_CATEGORY,
  EVENT_LABEL,
} from '@helpers/events';

const Wrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  color: #000 !important;
  padding: 4px 8px;
  // font-weight: 600;

  &:hover {
    background-color: #eee;
    // color: var(--white) !important;
  }

  .image {
    width: 24px;
    margin-right: 8px;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;

  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const AIM_DELAY = 80;

type MegaMenuItemProps = {
  data: ICategory;
  isActive: boolean;
  setActiveId: (id: number) => void;
};

const MegaMenuItem = React.memo<MegaMenuItemProps>(
  ({ data, isActive, setActiveId }) => {
    const timeoutRef: MutableRefObject<any> = useRef(null);

    const onMouseEnterHandler = (): void => {
      if (!timeoutRef?.current && !isActive) {
        timeoutRef.current = setTimeout(() => {
          timeoutRef.current = null;
          setActiveId(_get(data, 'id'));
        }, AIM_DELAY);
      }
    };

    const onMouseLeaveHandler = (): void => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };

    return (
      <Link
        href={categoryHref(_get(data, 'id'), _get(data, 'urlKey'))}
        passHref
      >
        <Wrapper
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={onMouseLeaveHandler}
          className={cn(EVENT_CLASS_NAME)}
          data-category={EVENT_CATEGORY.CATEGORY_MAIN_MENU}
          data-action={EVENT_ACTION_NAME}
          data-label={_get(data, 'name')}
        >
          <Flex>
            {/* <LazyImage
              src={_get(data, 'imageIcon') || '/assets/icon/categories.png'}
              alt={_get(data, 'name')}
              width="24"
              className="image"
            /> */}
            <LazyLoadImage
              image={{
                src: _get(data, 'imageIcon') || '/assets/icon/categories.png',
                width: 24,
                className: 'image',
              }}
            />
            <span>{_truncate(_get(data, 'name'), { length: 30 })}</span>
          </Flex>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
          >
            <path
              fill="#6F787E"
              fillRule="nonzero"
              d="M13.214 12L8 6.476 9.393 5 16 12l-6.607 7L8 17.524z"
            />
          </svg>
        </Wrapper>
      </Link>
    );
  }
);

type Props = {
  data: ICategory[];
  activeId: number;
  setActiveId: (id: number) => void;
};

const MainCategory = React.memo<Props>(({ data, activeId, setActiveId }) => {
  return (
    <>
      {data.map((item) => (
        <MegaMenuItem
          key={_get(item, 'id')}
          data={item}
          isActive={activeId === _get(item, 'id')}
          setActiveId={setActiveId}
        />
      ))}
    </>
  );
});

export default MainCategory;
