import React, {
  useState,
  useCallback,
  memo,
  FunctionComponent,
  Fragment,
} from 'react';
import { Form, Button, Alert, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useTimeout from '@restart/hooks/useTimeout';
import { DELAY } from '@helpers/const';
import { MODAL_FORM } from '../intl';
import { REGEX_PASSWORD } from '@server/lib/const';
import { useMutation } from '@apollo/react-hooks';
import { REGISTER } from '@graphql/mutation/user';
import _get from 'lodash/get';
import SocialLogin from './social-login';
import useGoogleRecaptcha from '../hooks/use-google-re-captcha';

const SIGN_UP_SCHEMA = Yup.object().shape({
  name: Yup.string().required(MODAL_FORM.REQUIRED),
  password: Yup.string()
    // .matches(REGEX_PASSWORD, MODAL_FORM.PASSWORD_REQUIRED)
    .required(MODAL_FORM.REQUIRED),
  passwordConfirmation: Yup.string()
    // .matches(REGEX_PASSWORD, MODAL_FORM.PASSWORD_REQUIRED)
    .oneOf(
      [Yup.ref('password'), null],
      MODAL_FORM.PASSWORD_CONFIRMATION_REQUIRED
    )
    .required(MODAL_FORM.REQUIRED),
  email: Yup.string()
    .email(MODAL_FORM.EMAIL_INVALID)
    .required(MODAL_FORM.REQUIRED),
});

interface IValues {
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

const INITIAL_VALUES: IValues = {
  name: '',
  email: '',
  password: '',
  passwordConfirmation: '',
};

const Message = memo<any>(({ onGotoLogin }) => (
  <div>
    <div className="reset-empty">
      <div className="d-flex justify-content-center">
        <img src="/assets/skeleton-01.svg" className="img-fluid" alt="Info" />
      </div>
      <div className="text-center">
        <h3 className="mt-4 mb-2">{MODAL_FORM.WELCOME_MESSAGE}</h3>
        <p>{MODAL_FORM.SIGN_UP_SUCCESS_MESSAGE}</p>
        <Button variant="primary" onClick={onGotoLogin}>
          {MODAL_FORM.LOGIN}
        </Button>
      </div>
    </div>
  </div>
));

interface SignUpProps {
  onGotoLogin: () => void;
  onForgetPassword: () => void;
  onClose: () => void;
}

export const SignUp: FunctionComponent<SignUpProps> = ({
  onClose,
  onGotoLogin,
  onForgetPassword,
}) => {
  const [success, setSuccess] = useState(false);
  const [isFetchError, setIsFetchError] = useState(false);
  const [fetchMessage, setFetchMessage] = useState(null);
  const [fetchRegister] = useMutation(REGISTER);
  const [recaptcha, getTokenAsync] = useGoogleRecaptcha();

  const timeout = useTimeout();
  const clearFetchError = useCallback(() => {
    setFetchMessage(null);
    setIsFetchError(false);
  }, [isFetchError]);

  const setFetchError = useCallback((message) => {
    setFetchMessage(message);
    setIsFetchError(true);
  }, []);

  const onSubmit = async (values: IValues, { setSubmitting }) => {
    try {
      const reCaptchaToken = await getTokenAsync();
      const variables = {
        ...values,
        recaptchaResponse: reCaptchaToken,
        recaptchaType: 'invisible',
      };

      await fetchRegister({
        variables,
        update: async () => {
          setSuccess(true);
          setSubmitting(false);

          // await onClose();
        },
      });
    } catch (error) {
      setFetchError(
        _get(error, 'graphQLErrors[0].message', MODAL_FORM.WRONG_SIGN_UP)
      );
      timeout.set(clearFetchError, DELAY);
    }
  };

  if (success) {
    return <Message onGotoLogin={onGotoLogin} />;
  }

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={SIGN_UP_SCHEMA}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form noValidate className="form form--sign" onSubmit={handleSubmit}>
          {isFetchError && <Alert variant="danger">{fetchMessage}</Alert>}
          <Form.Group>
            <Form.Control
              required
              type="input"
              placeholder={MODAL_FORM.NAME}
              name="name"
              value={values.name}
              onChange={handleChange}
              isInvalid={!!errors.name && !!touched.name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="email"
              placeholder={MODAL_FORM.EMAIL}
              name="email"
              value={values.email}
              onChange={handleChange}
              isInvalid={!!errors.email && !!touched.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="password"
              placeholder={MODAL_FORM.PASSWORD}
              name="password"
              value={values.password}
              onChange={handleChange}
              isInvalid={!!errors.password && !!touched.password}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Control
              type="password"
              placeholder={MODAL_FORM.PASSWORD_CONFIRMATION}
              name="passwordConfirmation"
              value={values.passwordConfirmation}
              onChange={handleChange}
              isInvalid={
                !!errors.passwordConfirmation && !!touched.passwordConfirmation
              }
            />
            <Form.Control.Feedback type="invalid">
              {errors.passwordConfirmation}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="d-flex">
            <Button
              type="submit"
              variant="primary"
              className="flex-fill d-flex justify-content-center align-items-center"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Fragment>
                  <Spinner
                    as="span"
                    animation="grow"
                    role="status"
                    aria-hidden="true"
                  />
                  {MODAL_FORM.SUBMITTING}
                </Fragment>
              ) : (
                <Fragment>{MODAL_FORM.SIGN_UP}</Fragment>
              )}
            </Button>
          </div>
          <div className="reset-password">
            <a className="text-primary" onClick={onForgetPassword}>
              {MODAL_FORM.FORGET_PASSWORD}
            </a>
          </div>
          <div className="switch-mode">
            {MODAL_FORM.HAS_ACCOUNT_YET}{' '}
            <a className="text-primary" onClick={onGotoLogin}>
              {MODAL_FORM.LOGIN}
            </a>
          </div>

          <div className="mt-4 d-flex justify-content-center">{recaptcha}</div>

          <SocialLogin onClose={onClose} />
        </Form>
      )}
    </Formik>
  );
};

export default memo(SignUp);
