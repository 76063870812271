import React, { Fragment, useEffect, useCallback, memo } from 'react';
import Link from 'next/link';
import { Modal, Container, Nav, NavItem } from 'react-bootstrap';
import Navbar from '@views/components/nav/Navbar';
import { Title, Transition } from '@views/components/login';

import { HEADER } from '../intl';
import IconAlert from '@views/static/icon-alert.svg';
import IconPromotion from '@views/static/icon-tag.svg';
import IconShare from '@views/static/icon-share.svg';
import IconReport from '@views/static/report.svg';

import SignUp from './signup';
import Login from './login';
import ForgetPassword from './forget-password';

import { MODAL_FORM } from '../intl';
import { FormType } from '../data/auth-form.interface';
// import { INTERNAL_ROUTES } from '@helpers/route';

import { useLoginSocialMutation } from '@views/modules/header/sub-modules/top/hooks/use-login';
import { ME } from '@views/modules/header/hooks/use-auth';
// import { useConfig } from '@server/lib/config';
import { useAlert } from 'react-alert';
import _get from 'lodash/get';
import { parse } from 'query-string';
import {
  IFBResponse,
  FBResponseStatus,
} from '@views/modules/facebook/use-facebook-login';
import useTimeout from '@restart/hooks/useTimeout';
import useLayoutContext from '@views/layouts/hooks/use-context';

enum SocialLoginType {
  Facebook = 'facebook',
  Google = 'google',
}

const LOGIN_FAILED = 'Không đăng nhập thành công. Vui lòng thử lại';

const isRedirectedFromFb = () => {
  const params = parse(window.location.search);
  return (
    _get(params, 'state') === 'facebookdirect' &&
    (_get(params, 'code') || _get(params, 'granted_scopes'))
  );
};

import {
  useAuthFormQuery,
  useLoginMutation,
  useSignupMutation,
  useCloseMutation,
  useForgetPasswordMutation,
} from '../hooks/use-auth-form';

export const HeaderTopNotAuth = () => {
  const { getAuthForm } = useAuthFormQuery();
  const [setLoginForm] = useLoginMutation();
  const [setSignupForm] = useSignupMutation();
  const [setCloseForm] = useCloseMutation();
  const [setForgotPassword] = useForgetPasswordMutation();
  const timeout = useTimeout();

  const show = getAuthForm.get('show') || false;
  const formType = getAuthForm.get('type');

  const handleOpenSignup = useCallback(() => {
    setSignupForm();
  }, [formType]);

  const handleOpenLogin = useCallback(() => {
    setLoginForm();
  }, [formType]);

  const handleForgetPassword = useCallback(() => {
    setForgotPassword();
  }, [formType]);

  const handleClose = useCallback(async () => {
    await setCloseForm();
  }, []);

  const alert = useAlert();
  const {
    checkout: { handleRefetchCart },
  } = useLayoutContext();
  const [fetchLoginSocial] = useLoginSocialMutation();

  const checkFacebookLoginState = async (response: IFBResponse) => {
    if (response.status !== FBResponseStatus.Connected) {
      alert.error(LOGIN_FAILED);

      return;
    }

    try {
      await fetchLoginSocial({
        variables: {
          accessToken: _get(response, 'authResponse.accessToken'),
          type: SocialLoginType.Facebook,
        },
        update: async (cache: any, { data: { loginSocial } }) => {
          await handleRefetchCart();

          cache.writeQuery({
            query: ME,
            data: { me: loginSocial },
          });
        },
      });
    } catch (error) {
      alert.error(_get(error, 'graphQLErrors[0].message', LOGIN_FAILED));
    }
  };

  useEffect(() => {
    timeout.set(() => {
      if (isRedirectedFromFb() && typeof FB !== undefined) {
        FB.getLoginStatus(checkFacebookLoginState);
      }
    }, 3000);
  }, []);

  return (
    <Fragment>
      <Navbar top>
        <Container className="justify-content-end">
          <Nav>
            <NavItem>
              <Link href="/bao-gia" passHref>
                <a className="nav-link active">
                  <IconReport className="header__icon" />
                  <span>Nhận báo giá RẺ hơn</span>
                </a>
              </Link>
            </NavItem>
            {/* <NavItem>
              <a
                href="//vanphongpham.fast.vn"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link active"
              >
                <IconShare className="header__icon" />
                {HEADER.SHARE}
              </a>
            </NavItem> */}
            <NavItem>
              <Link href="/bai-viet/chinh-sach-uu-dai">
                <a className="nav-link active">
                  <IconPromotion className="header__icon" />
                  {HEADER.PROMOTION}
                </a>
              </Link>
            </NavItem>
            <NavItem>
              <a className="nav-link" onClick={handleOpenSignup}>
                <IconAlert className="header__icon" />
                <span>{HEADER.NOTIFICATION}</span>
              </a>
            </NavItem>
            <NavItem>
              <a className="nav-link" onClick={handleOpenLogin}>
                {HEADER.LOGIN}
              </a>
            </NavItem>
            <NavItem>
              <a className="nav-link" onClick={handleOpenSignup}>
                {HEADER.SIGN_UP}
              </a>
            </NavItem>
          </Nav>
        </Container>
      </Navbar>

      <Modal
        show={show}
        onHide={() => setCloseForm()}
        className="modal-mobile-full"
      >
        <Modal.Body className="modal__body modal__body--form">
          {formType === FormType.Login && (
            <Transition>
              <Title title={MODAL_FORM.LOGIN} onClose={handleClose} />
              <Login
                onClose={handleClose}
                onGotoSignUp={handleOpenSignup}
                onForgetPassword={handleForgetPassword}
              />
            </Transition>
          )}

          {formType === FormType.Signup && (
            <Transition>
              <Title title={MODAL_FORM.SIGN_UP} onClose={handleClose} />
              <SignUp
                onClose={handleClose}
                onGotoLogin={handleOpenLogin}
                onForgetPassword={handleForgetPassword}
              />
            </Transition>
          )}

          {formType === FormType.Forgot && (
            <Transition>
              <Title title={MODAL_FORM.FORGET_PASSWORD} onClose={handleClose} />
              <ForgetPassword
                onClose={handleClose}
                onGotoLogin={handleOpenLogin}
                onGotoSignUp={handleOpenSignup}
              />
            </Transition>
          )}
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default memo(HeaderTopNotAuth);
