import { createContext } from 'react';

export interface ICartContext {
  data: any;
  loading: boolean;
  error: boolean;
  fetchCart: () => void;
}

export interface ICheckoutItemInput {
  id: number;
  quantity: number;
}

export interface ICheckoutContext {
  data: any;
  loading: boolean;
  error: any;
  selectedGift: any;
  handleRefetchCart: () => Promise<any>;
  handleAddItem: (data: ICheckoutItemInput) => Promise<any>;
  handleUpdateItem: (data: ICheckoutItemInput) => Promise<any>;
  handleDeleteItem: (data: { id: number }) => Promise<any>;
  handleCleanCart: () => void;
  handleChooseGift: (newGift: any) => void;
  handleCleanGift: () => void;
}
export interface MainLayoutContextValue {
  me: any;
  authenticated: boolean;
  error: boolean;
  loading: boolean;
  fetchMe: () => void;
  // cart: ICartContext;
  checkout: ICheckoutContext;
  activeCartToast: boolean;
  setActiveCartToast: (value: boolean) => void;

  overlay: boolean;
  setOverlay: (value: boolean) => void;
}

export const MainLayoutContext = createContext<MainLayoutContextValue>(
  undefined
);
